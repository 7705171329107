import React, {useEffect, useState} from 'react';
import {
  Box,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Modal,
  Switch,
  Tab,
  TextField
} from '@material-ui/core';
import {TabContext, TabPanel} from '@material-ui/lab';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import './overlay.css'
import {UserOverlayApi} from "../../service/http-out/userOverlay";
import {OverlaySettingsApi} from "../../service/http-out/overlaySettings";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from 'uuid';
import {addOverlayLayer, addTematicoLayer, removeLayerById, } from '../../store/mapSlice';
import {useTranslation} from 'react-i18next';
import {GlobalContext} from '../../contexts/globalContext';
import {CancelButton, SaveButton} from "../../style/globalStyle";
import {CartoApi} from 'service/http-out/carto-v3';
import BackspaceIcon from '@mui/icons-material/Backspace';
import {toast} from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import {setLegendColors} from 'store/appSlice';
import {OVERLAY_LAYER_ID} from "../layers/OverlayLayer";
import {OVERLAY_TEMATIC_LAYER_ID} from '../layers/TematicoLayer';

const useStyles:any = makeStyles((theme) => ({
  editFilter: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  scrollFilter: {
    //overflowY: 'scroll',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    width: '95%',
    minHeight: '10vh',

    marginTop: '25px',
    marginBottom: '25px',
    padding: '0 30px 50px 30px',
    boxShadow:
      '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)',
  },
  textName: {
    width: '70%',
    minWidth: '30px',
  },
  title: {
    marginBottom: '40px'
  },
  textNumber: {
    width: '15%',
    minWidth: '30px',
    margin: '10px 5px 10px 5px',
  },
  textTam: {
    width: '30%',
    minWidth: '30px',
    margin: '10px 5px 10px 5px',
  },
  fixarRodape: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px',
    position: 'fixed',
    bottom: '30px',
    width: '90%',
    textAlign: 'center',
    alignSelf: 'end',
  },
  columnC: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    width: '100%'
  },
  columnCircle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    alignItems: 'center',
    alignContent: 'center'
  },
  rowLeft: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    alignItems: 'center',
    alignContent: 'left'
  },
  rowCircle: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    alignItems: 'center',
    alignContent: 'center',
    overflowX: 'scroll'
  },
  textColor: {
    width: '15%',
    minWidth: '30px',
    margin: '0px 5px opx 5px',
  },
  divTab: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    marginBottom: '15%',
    height: '550px',
    maxHeight: '80%',
    //position: 'fixed',
  },
  marginBottom: {
    marginBottom: '10px'
  },
  marginBotTop: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  select: {
    width: '15%',
    marginRight: '10px'
  },
  center: {
    display: 'flex',
    //justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  centerMax: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  iconLayer: {
    width: '35px',
    height: '35px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  order: {
    width: '30%',
    margin: '10px 10px 10px 10px'
  },
  rowBeet: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
  columnOver: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    marginBottom: '5%',
  },
  divFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },
  buttonIcon: {
    margin: '0px 10px 0px 10px',
  },
  divHelpColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  textCompany: {
    width: '50%',
    marginTop: '10px',
  },
  textLabel: {
    width: '30%',
    marginTop: '10px',
    marginRight: '10px',
  },
  textLabelsub: {
    width: '10%',
    marginTop: '10px',
    marginRight: '10px',
  },
  textTematics: {
    width: '90%',
    marginRight: '10px'
  },
  textTematics30: {
    width: '30%',
    marginRight: '10px'
  },
  textTematics10: {
    maxWidth: '15%',
    minWidth: '120px',
    marginRight: '10px'
  },
  margin5: {
    maxHeight: '600px',
    overflow: 'scroll',
  },
  divCircle: {
    marginLeft: '5px',
    marginRight: '5px',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails: any = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  //overflowY: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '90%',
  marginTop: '30px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const THEMATIC_TYPE = { COLOR: 'color', SIZE: 'size', COLOR_SIZE: 'color-size'};
export default function OverlayModal({ isOverlayModalVisible, setBaseOverlayModalVisible }: { isOverlayModalVisible: boolean, setBaseOverlayModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  if (!isOverlayModalVisible) {
    return (<></>);
  }
  const handleClose = () => setBaseOverlayModalVisible(false);
  const [value, setValue] = useState<string>('0');
  const classes = useStyles();
  const [overlays, setOverlays] = useState([]);
  const [variablesFilter, setVariablesFilter] = useState<Array<any>>([]);
  const [variablesTematics, setVariablesTematics] = useState<Array<any>>([]);
  const [variablesTematicsNumber, setVariablesTematicsNumber] = useState<Array<any>>([]);
  const [valueColorString, setValueColorString] = useState<Array<any>>([]);
  const [lengthColorGroup, setLengthColorGroup] = useState<Array<any>>([]);
  const [orderOverlays, setOrderOverlays] = useState<Array<any>>([]);
  const [filt, setFilt] = React.useState('number');
  const [name, setName] = useState("");
  const [tematicoInsertDTO, setTematicoInsertDTO] = useState({
    type: '',
    id: undefined,
    sizeSettings: {
      variable: {
        id: undefined, label: undefined,
      },
    },
    colorSettings: {
      variable: {
        label: undefined, id: undefined,
      },
    },
  });
  const [tableTematicOverlay, setTableTematicOverlay] = useState("");
  const [columnTematicOverlay, setColumnTematicOverlay] = useState({});
  const dispatch = useDispatch();
  const [minWidth, setMinWidth] = useState(10);
  const [maxWidth, setMaxWidth] = useState(40);
  const [nGroupColor, setNGroupColor] = React.useState('1');
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [groupColor, setGroupColor] = useState<Array<any>>([]);
  const [inGroupColor, setInGroupColor] = useState(0)
  const [groupCircle, setGroupCircle] = useState<Array<any>>([]);
  const [inGroupCircle, setInGroupCircle] = useState(0)
  const [ngroupSize, setNgroupSize] = React.useState('1');
  const [opacity, setOpacity] = React.useState(10);
  const [colorBorder, setColorBorder] = useState('#000000');
  const [typeTematics, setTypeTematics] = useState('');
  const [colorTematic, setColorTematic] = useState('#FF0000');
  const [opacityBorder, setOpacityBorder] = React.useState(10);
  const [thicknessBorder, setThicknessBorder] = React.useState(0);
  const [variableColorSettings, setVariableColorSettings] = React.useState<any>({ column: "" });
  const [variableSizeSettings, setVariableSizeSettings] = React.useState<any>({ column: "" });
  const [classifiMethod, setClassifiMethod] = React.useState('');
  const [userTematics, setUserTematics] = useState([]);
  const [selectedOverlay, setSelectedOverlay] = useState<any>({})
  const [expanded, setExpanded] = React.useState<string | false>('panel');
  const color = ['#e6194b', '#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42D4F4', '#4363d8', '#911eb4', '#f032e6'];
  //@ts-ignore
  const { t, i18n } = useTranslation();
  //@ts-ignore
  const { overlayPontosChecked, setOverlayPontosChecked } = React.useContext(GlobalContext);
  const [openAddFilterOverlay, setOpenAddFilterOverlay] = useState(false);
  const handleCloseAddFilterOverlay = () => {
    resetOverlayFilter();
    setOpenAddFilterOverlay(false)
  };
  const handleAddFilterOverlay = (overlay: any) => {
    handleVariableAddFilter(overlay)
    setSelectedOverlay(overlay)
    setOpenAddFilterOverlay(true)
  };
  const [editOverlayFilter, setEditOverlayFilter] = useState(false)
  const [editOverlayFilterId, setEditOverlayFilterId] = useState('')
  const [typeVarOverlayFilter, setTypeVarOverlayFilter] = useState('STRING')
  function handleEditFilterOverlay(index: any, overlayFilter: any, overlay: any) {
    overlayFilterEdit(overlayFilter)
    console.log(overlayFilter)
    setEditOverlayFilter(true)
    handleVariableAddFilter(overlay)
    setSelectedOverlay(overlay)
    setOpenAddFilterOverlay(true)
  }
  const [filterVariable, setFilterVariable] = useState<Array<any>>([{ variable: "", condition: "", value: "", conjuct: "" }])
  const [addNameFilter, setAddNameFilter] = useState('')
  const [openAddTematic, setOpenAddTematic] = useState(false);
  const [overlayAddEditTematic, setOverlayAddEditTematic] = useState<any>({})
  const [checkBoxTypeColor, setCheckBoxTypeColor] = React.useState(false);
  const [checkBoxTypeSize, setCheckBoxTypeSize] = React.useState(false);
  const [selectLabelTypeColor, setSelectLabelTypeColor] = React.useState(undefined);
  const [selectLabelTypeSize, setSelectLabelTypeSize] = React.useState(undefined);
  const handleOpenAddTematic = (overlay: any) => {
    console.log(overlay)
    handleVariableAddTematics(overlay)
    setOpenAddTematic(true)
  };
  const handleCloseAddTematic = () => {
    setOpenAddTematic(false);
    resetTematicsModal()
  }

  function handleVariableAddFilter(overlay: any) {
    setVariablesFilter(overlay.tematics)
  }

  function handleEditFilterThematic(index: any, thematic: any) {
    console.log('thematic', thematic)
    setTematicoInsertDTO(thematic)
    setOpenAddTematic(true)
    setName(thematic.name)
    let overlay = overlays.find((overlay: any) => overlay.id === thematic.overlay.id)
    setOverlayAddEditTematic(overlay)
    if (thematic.type === THEMATIC_TYPE.COLOR) {
      setCheckBoxTypeColor(true)
      // @ts-ignore
      setVariablesTematics(overlay.tematics)
      // @ts-ignore
      setTableTematicOverlay(overlay.datasetName)
      setSelectLabelTypeColor(thematic.colorSettings.variable.label)
      setColumnTematicOverlay(thematic.colorSettings.variable.column)
      setTypeTematics(thematic.colorSettings.variable.type)
      setVariableColorSettings(thematic.colorSettings.variable);
      setNGroupColor(thematic.colorSettings.colorSetting.length as string)
      let pushTempLength = [];
      for (let i = 2; i <= thematic.colorSettings.colorSetting.length; i++) {
        pushTempLength.push(i)
      }
      setLengthColorGroup(pushTempLength)
      setValueColorString(thematic.colorSettings.colorSetting)
      setGroupColor(thematic.colorSettings.colorSetting)
      // handleFillColorLength(thematic.colorSettings.variable.type, thematic.colorSettings.variable.column)
    } else if (thematic.type === THEMATIC_TYPE.SIZE) {
      setCheckBoxTypeSize(true)
      handleVariableAddTematics(overlay)
    } else {
      setCheckBoxTypeColor(true)
      setCheckBoxTypeSize(true)
      handleVariableAddTematics(overlay)
      // @ts-ignore
      setVariablesTematics(overlay.tematics)
      // @ts-ignore
      setTableTematicOverlay(overlay.datasetName)
      setSelectLabelTypeColor(thematic.colorSettings.variable.label)
      setSelectLabelTypeColor(thematic.colorSettings.variable.label)
      setColumnTematicOverlay(thematic.colorSettings.variable.column)
      setTypeTematics(thematic.colorSettings.variable.type)
      setVariableColorSettings(thematic.colorSettings.variable);
      setNGroupColor(thematic.colorSettings.colorSetting.length as string)
      let pushTempLength = [];
      for (let i = 2; i <= thematic.colorSettings.colorSetting.length; i++) {
        pushTempLength.push(i)
      }
      setLengthColorGroup(pushTempLength)
      setValueColorString(thematic.colorSettings.colorSetting)
      setGroupColor(thematic.colorSettings.colorSetting)
      // handleFillColorLength(thematic.colorSettings.variable.type, thematic.colorSettings.variable.column)
    }
    setFilt(thematic.sizeSettings.variable.type)
    setColorTematic(thematic.sizeSettings.colorTematic)
    setOpacity(thematic.sizeSettings.opacity)
    setColorBorder(thematic.sizeSettings.borderColor)
    setOpacityBorder(thematic.sizeSettings.opacityBorder)
    setThicknessBorder(thematic.sizeSettings.thicknessBorder)
    setClassifiMethod(thematic.sizeSettings.classificationMethod)
    setInGroupCircle(thematic.sizeSettings.customCondition)
    setNgroupSize(thematic.sizeSettings.groupNumber)
    setGroupCircle(thematic.sizeSettings.customCircle)
    // handleChangeNgroupSize({target: {value: thematic.sizeSettings.groupNumber}})
    setVariableSizeSettings(thematic.sizeSettings.variable)
    console.log(thematic.sizeSettings.variable.type)
    console.log(thematic.sizeSettings.variable.label)
    // setTematicoInsertDTO(thematic)
    // handleChangeColorGroup
    // variablesTematics.map((tematic: any) => {
    //   tematic.checked(tematic.variable.column === thematic.colorSettings.variable.column)
    // })
    // console.log(variablesTematics)
  }
  function handleVariableAddTematics(overlay: any) {
    let pushTempNumber: any = []
    setOverlayAddEditTematic(overlay)
    setTableTematicOverlay(overlay.datasetName)
    setVariablesTematics(overlay.tematics)

    overlay.tematics.map((element: any) => {
      if (element.variable !== null) {
        if (element.variable.type === 'FLOAT64' || element.variable.type === 'INT64' || element.variable.type === 'NUMERIC') {
          pushTempNumber.push(element)
        }
      }
    })
    console.log(pushTempNumber)
    setVariablesTematicsNumber(pushTempNumber)
  }

  function overlayFilterEdit(overlayFilter: any) {
    let pushfilterEdit: any = []
    setAddNameFilter(overlayFilter.name)
    console.log(overlayFilter.id)
    setEditOverlayFilterId(overlayFilter.id)
    overlayFilter.conditions.map((item: any, index: any) => {
      let filter = { variable: item.variable, condition: item.condition, value: item.value, conjuct: item.conjuct }
      pushfilterEdit.push(filter)
    })
    setFilterVariable(pushfilterEdit)
  }

  async function handleFillColorLength(type: string, column: string) {
    console.log('handleFillColorLength', type, ' ', column)
    let pushTempLength: any = []
    if (type === 'FLOAT64' || type === 'INT64' || type === 'NUMERIC') {
      for (let i = 2; i <= 9; i++) {
        pushTempLength.push(i)
      }
      setNGroupColor('0')
    } else {
      await OverlaySettingsApi.listValue(tableTematicOverlay, column).then((res) => {
        let tempGroupcolor: any = []
        console.log(res.data)
        for (let i = 0; i <= 8; i++) {
          let tempColor = { name: res.data[i][column], condition: res.data[i][column], color: generateHexColor() }
          tempGroupcolor.push(tempColor)
        }
        for (let i = 2; i <= res.data.length; i++) {
          pushTempLength.push(i)
        }
        setGroupColor(tempGroupcolor.slice(0, 9))
        console.log(tempGroupcolor)
        setValueColorString(res.data);
        setGroupColor(tempGroupcolor)

        if (pushTempLength.length > 9) {
          setNGroupColor('9')
          setGroupColor(tempGroupcolor.slice(0, 9))
        } else {
          setNGroupColor(pushTempLength.length.toString())
          setGroupColor(tempGroupcolor)
        }
      })
    }
    setLengthColorGroup(pushTempLength)
  }

  function handleChangeVariableFilterVariable(index: any, value: any) {
    let foundVar = variablesFilter.find((element: any) => element.variable.column === value)
    console.log(foundVar)
    let tempValues = [...filterVariable];
    tempValues[index].variable = value;
    console.log(tempValues)
    setTypeVarOverlayFilter(foundVar.variable.type)
    setFilterVariable(tempValues);
    console.log(tempValues)
  }

  function handleChangeVariableFilterCondition(index: any, value: any) {
    let tempValues = [...filterVariable];
    tempValues[index].condition = value;
    setFilterVariable(tempValues);
  }

  function handleChangeVariableFilterSubCondition(index: any, value: any) {
    let tempValues = [...filterVariable];
    tempValues[index].conjuct = value;
    setFilterVariable(tempValues);
    console.log(tempValues)
  }

  function handleChangeVariableFilterValue(index: any, value: any) {
    let tempValues = [...filterVariable];
    tempValues[index].value = value;
    setFilterVariable(tempValues);
  }

  function saveAddFilterOverlay() {
    let filterAddOverlay: any = { name: addNameFilter, conditions: [], overlay: selectedOverlay }
    console.log(typeVarOverlayFilter)
    filterVariable.map((filter: any) => {
      filterAddOverlay.conditions.push({ variable: filter.variable,
        condition: filter.condition, value: filter.value,
        conjuct: filter.conjuct, type: typeVarOverlayFilter })
    })
    if (editOverlayFilter) {
      UserOverlayApi.updateUserOverlaysFilter(filterAddOverlay, editOverlayFilterId)
        .then((res) => {
          toast.success('Filtro editar com sucesso!')
          console.log(res);
          setOpenAddFilterOverlay(false);
          setEditOverlayFilter(false);
          resetOverlayFilter();
          refreshOverlayFilters();
        })
        .catch((err) => {
          toast.error('Ocorreu um erro ao editar um filtro!')
          console.log(err);
        });
    } else {
      UserOverlayApi.CreateUserOverlayFilter(filterAddOverlay)
        .then((res) => {
          toast.success('Filtro criado com sucesso!')
          console.log(res);
          setOpenAddFilterOverlay(false);
          resetOverlayFilter();
          refreshOverlayFilters();
        })
        .catch((err) => {
          toast.error('Ocorreu um erro ao criar um filtro!')
          console.log(err);
        });
    }
  }

  const addVariablesFilter = () => {
    setFilterVariable([...filterVariable, { variable: "", condition: "", value: "", conjuct: ' AND ' }]);
  }

  const removeVariablesFilter = (index: any) => {
    const values = [...filterVariable];
    values.splice(index, 1);
    setFilterVariable(values);
  }

  function changeMinWidth(event: any) {
    setMinWidth(event.target.value)
  }

  function changeMaxWidth(event: any) {
    setMaxWidth(event.target.value)
  }

  const handleChangeNgroup = (item: any) => {
    console.log(item)
    setNGroupColor(item as string);
    if (variableColorSettings.type === 'FLOAT64' || variableColorSettings.type === 'INT64' || variableColorSettings.type === 'NUMERIC') {
      if (item > groupColor.length) {
        let tempGC = [...groupColor]
        let temp = item - groupColor.length;
        for (let i = 1; i <= temp; i++) {
          tempGC = [...tempGC, { name: `Grupo ${groupColor.length + i}`, condition: 0, color: color[i + groupColor.length - 1] }];
        }
        setGroupColor(tempGC)
      } else if (item < groupColor.length) {
        let tempGC = [...groupColor]
        let temp = groupColor.length - item;
        tempGC.splice(item, temp);
        console.log(tempGC)
        setGroupColor(tempGC)
      }
    } else {
      if (item > groupColor.length) {
        console.log('Chegou aqui')
        let tempGC = [...groupColor]
        let temp = item - groupColor.length;
        console.log(temp)
        for (let i = 0; i < temp; i++) {
          tempGC = [...tempGC,
            { name: valueColorString[groupColor.length + i][variableColorSettings.column],
              condition: valueColorString[groupColor.length + i][variableColorSettings.column],
              color: generateHexColor() }];
        }
        console.log(tempGC)
        setGroupColor(tempGC)
      } else if (item < groupColor.length) {
        let tempGC = [...groupColor]
        let temp = groupColor.length - item;
        tempGC.splice(item, temp);
        console.log(tempGC)
        setGroupColor(tempGC)
      }
    }
  };

  const handleChangeNgroupSize = (event: any) => {
    console.log(event.target.value)
    setNgroupSize(event.target.value as string);
    if (classifiMethod === "Custom") {
      let pushCircle: any = []
      let divisor = 30 / event.target.value;
      let lastRadius = 10
      for (let i = 0; i < event.target.value; i++) {
        if (i > 0) {
          lastRadius = Math.round(lastRadius + divisor)
          lastRadius = lastRadius > 30 ? 30 : lastRadius
        }
        let tempCircle = { condition: 0, radius: lastRadius }
        pushCircle.push(tempCircle)
      }
      setGroupCircle(pushCircle)
    }
  };

  const handleChangeOpacity = (event: any) => {
    setOpacity(event.target.value);
    console.log(opacity);
  };

  const handleChangeOpacityBorder = (event: any) => {
    setOpacityBorder(event.target.value);
    console.log(opacityBorder);
  };

  const handleChangeColorBorder = (event: any) => {
    setColorBorder(event.target.value);
    console.log(colorBorder);
  };

  const handleChangeColorTematics = (event: any) => {
    console.log(event.target.value);
    setColorTematic(event.target.value);
  };

  const handleChangeThicknessBorder = (event: any) => {
    setThicknessBorder(event.target.value);
  };

  const handleChangeColorGroup = (value: any) => {
    console.log('handleChangeColorGroup', value);
    setColumnTematicOverlay(value.column)
    handleFillColorLength(value.type, value.column)
    setTypeTematics(value.type)
    setVariableColorSettings(value);
  };

  // const handleChangeColorGroup = (event: any) => {
  //   console.log('handleChangeColorGroup', event.target.value);
  //   setColumnTematicOverlay(event.target.value.column)
  //   handleFillColorLength(event.target.value.type, event.target.value.column)
  //   setTypeTematics(event.target.value.type)
  //   setVariableColorSettings(event.target.value);
  // };

  const handleChangeSizeGroup = (value: any) => {
    console.log('handleChangeSizeGroup', value);
    setVariableSizeSettings(value);
  };

  const handleChangeClassifiMethod = (event: any) => {
    setClassifiMethod(event.target.value as string);
    console.log(classifiMethod);
  };

  const handleChangeConditionTematics = (event: any, index: any) => {
    let tempGroupCircle = [...groupCircle]
    tempGroupCircle[index].condition = +event.target.value;
    setGroupCircle(tempGroupCircle)
  }

  const handleChangeRadiusTematics = (event: any, index: any) => {
    let tempGroupCircle = [...groupCircle]
    tempGroupCircle[index].radius = +event.target.value;
    setGroupCircle(tempGroupCircle)
    console.log(groupCircle)
  }

  function generateHexColor() {
    //@ts-ignore
    return '#' + parseInt((Math.random() * 0xFFFFFF)).toString(16).padStart(6, '0');
  }

  function generateName() {
    let nameSelected = ''
    if (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR) {
      nameSelected = name ? name : variableColorSettings.label + '(' + groupColor.length + ')';
    } else if (tematicoInsertDTO.type === THEMATIC_TYPE.SIZE) {
      nameSelected = name ? name : variableSizeSettings.label + '(' + ngroupSize + ')';
    } else if (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE) {
      nameSelected = name ? name : variableColorSettings.label + '(' + groupColor.length + ') ' + variableSizeSettings.label + '(' + ngroupSize + ')';
    }
    return nameSelected;
  }

  function clickSaveTematics() {
    groupCircle.map((item: any, index: any) => {
      groupCircle[index].radius = item.radius;
    });
    let tematic: any = {
      id: tematicoInsertDTO.id,
      name: generateName(),
      type : tematicoInsertDTO.type,
      overlay: overlayAddEditTematic,
      colorSettings: {
        variable: variableColorSettings,
        colorSetting: groupColor,
        condition: inGroupCircle
      },
      sizeSettings: {
        colorTematic: colorTematic,
        variable: variableSizeSettings,
        classificationMethod: classifiMethod,
        groupNumber: ngroupSize,
        customCircle: groupCircle,
        customCondition: inGroupCircle,
        circleMinWidth: minWidth,
        circleMaxWidth: maxWidth,
        opacity: opacity,
        borderColor: colorBorder,
        opacityBorder: opacityBorder,
        thicknessBorder: thicknessBorder
      }
    }

    console.log('tematic', tematic)
    if (!tematicoInsertDTO.id) {
      UserOverlayApi.createUserOverlaysThematic(tematic).then((response: any) => {
        console.log(response)
        overlayPontosChecked.tematicos.push(response.data.id)
        handleCloseAddTematic();
        resetOverlayTematics()
        resetTematicsModal()
      }).catch((error: any) => {
        console.log(error)
        alert("Erro na criação do Tematico");
      })
    } else {
      delete tematic.id
      UserOverlayApi.updateUserOverlaysThematic(tematicoInsertDTO.id, tematic).then((response: any) => {
        console.log(response)
        overlayPontosChecked.tematicos.push(response.data.id)
        handleCloseAddTematic();
        resetOverlayTematics()
        resetTematicsModal()
      }).catch((error: any) => {
        console.log(error)
        alert("Erro na edição do Tematico");
      })
    }
  }

  function resetTematicsModal(){
    setNGroupColor("1")
    setGroupColor([])
    setName('')
    setTematicoInsertDTO({
      type: '',
      id: undefined,
      sizeSettings: {
        variable: {
          id: undefined, label: undefined,
        },
      },
      colorSettings: {
        variable: {
          label: undefined, id: undefined,
        },
      },
    })
  }

  function handleChangeOrderOverlays(event: any, index: any) {
    let tempOrder = [...orderOverlays];
    tempOrder[index].order = +event.target.value
    console.log(tempOrder)

    setOrderOverlays(tempOrder)
  }

  useEffect(() => {
    refreshOverlayFilters()
    resetOverlayTematics()
    if (overlayPontosChecked.tematicos === undefined) {
      setOverlayPontosChecked({
        overlays: [],
        filters: [],
        tematicos: [],
      })
    }
  }, [])

  function resetOverlayTematics(){
    UserOverlayApi.getUserOverlaysThematics().then((res: any) => {
      setUserTematics(res.data)
    }).catch((error: any) => {
      console.log(error)
    });
  }

  function resetOverlayFilter() {
    setAddNameFilter('')
    setFilterVariable([{ variable: "", condition: "", value: "", conjuct: "" }])
  }

  function refreshOverlayFilters() {
    const packsString: any = localStorage.getItem('overlays')
    const packsObject: any = JSON.parse(packsString)
    let overlayList = extractOverlays(packsObject)
    UserOverlayApi.getUserOverlaysFilters()
      .then((res) => {
        res.data.map((filter: any) => {
          overlayList.map((overlay: any, index: any) => {
            if (overlay.id === filter.overlay.id) {
              if (!overlay.filters) {
                overlay.filters = []
              }
              overlay.filters.push(filter)
            }
          })
        })
        console.log('overlayList', overlayList)
        setOverlays(overlayList)
      });
  }

  function extractOverlays(packs: any) {
    let overlayList: any = []
    packs.map((pack: any) => {
      pack?.overlays.map((overlay: any) => {
        let found = overlayList.find((element: any) => element.overlay === overlay.overlay)
        if (found === undefined) {
          overlayList.push(overlay);
        }
      })
    })
    return overlayList
  }

  const handleChangeSwitchChecked = (e: any) => {
    setCheckBoxTypeColor(e.target.checked)
    if (tematicoInsertDTO.type === THEMATIC_TYPE.SIZE) {
      tematicoInsertDTO.type = THEMATIC_TYPE.COLOR_SIZE
    } else {
      tematicoInsertDTO.type = THEMATIC_TYPE.COLOR
    }
    setTematicoInsertDTO(tematicoInsertDTO)
  };

  const handleChangeSwitchTwoChecked = (e: any) => {
    setCheckBoxTypeSize(e.target.checked)
    if (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR) {
      tematicoInsertDTO.type = THEMATIC_TYPE.COLOR_SIZE
    } else {
      tematicoInsertDTO.type = THEMATIC_TYPE.SIZE
    }
    setTematicoInsertDTO(tematicoInsertDTO)
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setExpanded('panel')
  };

  function applyLayersToMap() {
    addOverlaysToMap()
    // addFilterOverlaysToMap()
    addTematicOverlaysToMap()
  }

  function addTematicOverlaysToMap() {
    toast.info("Carregando...")
    setLoadingOverlay(true)
    dispatch(removeLayerById(OVERLAY_TEMATIC_LAYER_ID));
    if (overlayPontosChecked.overlays.length === 0 && overlayPontosChecked.tematicos.length > 0) {
      userTematics.map((thematic: any) => {
        if (overlayPontosChecked.tematicos.find((item: any) => item === thematic.id)) {
          let columnSize = thematic.colorSettings.variable.column
          let caseCond = ''
          let columnColor = ''
          if (thematic.type === THEMATIC_TYPE.COLOR_SIZE || thematic.type === THEMATIC_TYPE.SIZE) {
            columnSize = thematic.sizeSettings.variable.column
            if (thematic.type === THEMATIC_TYPE.COLOR_SIZE) {
              columnColor = ", "+ thematic.colorSettings.variable.column
            }
            caseCond += `, CASE `
            thematic.sizeSettings.customCircle.sort(function(a: any, b: any) {
              return a.condition - b.condition;
            }).map((circle: any, index: any) => {
              if (index === 0) {
                caseCond += `WHEN ${columnSize} >= ${thematic.sizeSettings.customCondition} AND ${columnSize} < ${circle.condition} THEN ${circle.radius} `
              } else {
                caseCond += `WHEN ${columnSize} >= ${thematic.sizeSettings.customCircle[index-1].condition} AND ${columnSize} < ${circle.condition} THEN ${circle.radius} `
              }
            })
            caseCond += `END AS circle_size`
          }
          let query = `select geom, ${columnSize} ${columnColor} ${caseCond} from bigquerytest-328219.teste_gdm.`
            + thematic.overlay.datasetName
          overlays.map((overlay: any) => {
            if (overlay.id === thematic.overlay.id) {
              query += ` where `
              overlay?.filters?.map((filter: any) => {
                if (overlayPontosChecked.filters.find((item: any) => item === filter.id)) {
                  filter.conditions.map((condition: any, index: any) => {
                    console.log(condition)
                    condition.type === 'STRING' ?
                      query += `${condition.variable} ${condition.condition} '${condition.value}'   AND `
                      :
                      query += `${condition.variable} ${condition.condition} ${condition.value}   AND `
                  });
                }
              })
              query = query.substring(0, query.length - 6);
              console.log('query', query)

              CartoApi.runQuery('/maps/teste/query', 'geojson', query).then((res: any) => {
                dispatch(setLegendColors({
                  name: thematic.name,
                  type: thematic.type,
                  items: thematic
                }));
                dispatch(addTematicoLayer(res.data, thematic, OVERLAY_TEMATIC_LAYER_ID));
                handleClose()
                setLoadingOverlay(false)
              }).catch((erro: any) => {
                console.log(erro);
                setLoadingOverlay(false)
              })
            }
          })
        }
      });
    }
  }

  function addOverlaysToMap() {
    overlays.map((overlay: any) => {
      if (haveLayersChecked(overlay.layers)) {
        dispatch(removeLayerById(OVERLAY_LAYER_ID));
        toast.info("Carregando...")
        setLoadingOverlay(true)
        let query = 'select%20*%20from%20bigquerytest-328219.teste_gdm.'
        query += overlay.datasetName + ' where ' + overlay.layers[0].variableName + ' in(';
        overlay.layers.map((layer: any) => {
          if (isLayerSelected(layer)) {
            query += "'" + layer.value + "',";
          }
        });
        query = query.substring(0, query.length - 1) + ') ';
        console.log(query)

        overlay?.filters?.map((filter: any) => {
          if (isFilterSelected(filter)) {
            filter.conditions.map((condition: any, index: any) => {
              console.log(condition)
              condition.type === 'STRING' ?
                query += ` AND ${condition.variable} ${condition.condition} '${condition.value}' `
                :
                query += ` AND ${condition.variable} ${condition.condition} ${condition.value} `
            });
          }
        })
        console.log(query)

        CartoApi.runQuery('/maps/teste/query', 'geojson', query).then((res: any) => {
          dispatch(addOverlayLayer(res.data, overlay.layers[0]));
          handleClose()
          setLoadingOverlay(false)
        }).catch((erro: any) => {
          toast.error("Erro ao aplicar o error")
          console.log(erro);
          setLoadingOverlay(false)
        })
      }
    });
  }

  function isLayerSelected(layer: any) {
    let status = false
    overlayPontosChecked.overlays.map((layerChecked: any) => {
      if (layer.id === layerChecked) {
        status = true
      }
    })
    return status
  }

  function isFilterSelected(filter: any) {
    let status = false
    overlayPontosChecked.filters.map((filterChecked: any) => {
      if (filter.id === filterChecked) {
        status = true
      }
    })
    return status
  }

  function haveLayersChecked(layers: any) {
    let status = false
    layers.map((layer: any) => {
      overlayPontosChecked.overlays.map((layerChecked: any) => {
        if (layer.id === layerChecked) {
          status = true
        }
      })
    })
    return status
  }

  const handleChangeConditionGC = (index: any, event: any) => {
    let temp = [...groupColor]
    temp[index].condition = event;
    setGroupColor(temp);
  };

  const handleChangeName = (index: any, event: any) => {
    let temp = [...groupColor]
    temp[index].name = event;
    setGroupColor(temp);
  };

  const handleChangeColor = (index: any, event: any) => {
    let temp = [...groupColor]
    temp[index].color = event;
    setGroupColor(temp);
  };

  const handleChangeColorString = (index: any, event: any) => {
    let temp = [...groupColor]
    temp[index].color = event;
    setGroupColor(temp);
  };

  function handleDeleteFilterOverlay(index: any, overlayFilter: any, overlay: any) {
    UserOverlayApi.deleteUserOverlaysFilter(overlayFilter.id)
      .then((res) => {
        overlay.filters.splice(index, 1);
        setOverlays([...overlays]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangeCheckedFilters(e: any, overlayFilter: any) {
    if (e.target.checked) {
      overlayPontosChecked.filters.push(overlayFilter.id)
    } else {
      overlayPontosChecked.filters.splice(
        overlayPontosChecked.filters.findIndex((item: any) => item === overlayFilter.id), 1)
    }
    setOverlayPontosChecked(overlayPontosChecked)
    setOverlays([...overlays])
  }

  const handleChangeCheckedLayers = (e: any, layer: any) => {
    if (e.target.checked) {
      overlayPontosChecked.overlays.push(layer.id)
    } else {
      overlayPontosChecked.overlays.splice(
        overlayPontosChecked.overlays.findIndex((item: any) => item === layer.id), 1)
    }
    setOverlayPontosChecked(overlayPontosChecked)
    setOverlays([...overlays])
  }

  const handleChangeCheckedAllLayers = (e: any, idOverlay: string) => {
    overlays.map((overlay: any) => {
      if (overlay.id === idOverlay) {
        overlay.layers.map((layer: any) => {
          if (e.target.checked) {
            overlayPontosChecked.overlays.push(layer.id)
          } else {
            overlayPontosChecked.overlays.splice(
              overlayPontosChecked.overlays.findIndex((item: any) => item === layer.id), 1)
          }
        })
      }
    })
    setOverlayPontosChecked(overlayPontosChecked)
    setOverlays([...overlays])
  }

  function handleChangeCheckedThematic(e: any, thematic: any) {
    if (e.target.checked) {
      overlayPontosChecked.tematicos.push(thematic.id)
    } else {
      overlayPontosChecked.tematicos.splice(
        overlayPontosChecked.tematicos.findIndex((item: any) => item === thematic.id), 1)
    }
    setOverlayPontosChecked(overlayPontosChecked)
    setUserTematics([...userTematics])
  }

  function calculateMinMax(from: string, ngroup: string) {
    let query
    if (from === 'color') {
      query = `SELECT MIN(${variableColorSettings.column}) AS min,
       MAX(${variableColorSettings.column}) AS max
            FROM teste_gdm.${tableTematicOverlay}`
    } else {
      query = `SELECT MIN(${variableSizeSettings.column}) AS min,
       MAX(${variableSizeSettings.column}) AS max
            FROM teste_gdm.${tableTematicOverlay}`
    }
    CartoApi.runQuery('/sql/teste/query', 'json', query).then((res: any) => {
      if (from === 'color') {
        setInGroupColor(res.data.rows[0].min)
        handleChangeConditionGC(+ngroup - 1, res.data.rows[0].max)
      } else {
        setInGroupCircle(res.data.rows[0].min)
        let tempGroupCircle = [...groupCircle]
        tempGroupCircle[+ngroup - 1].condition = res.data.rows[0].max;
        setGroupCircle(tempGroupCircle)
      }
    }).catch((err: any) => {
      console.log(err);
    })
  }

  function handleDeleteFilterThematic(index: any, thematic: any) {
    UserOverlayApi.deleteUserOverlaysThematic(thematic.id)
      .then((res: any) => {
        userTematics.splice(index, 1);
        setUserTematics([...userTematics]);
      })
      .catch((err: any) => {
        console.log(err);
      });
    console.log(thematic)
  }

  const handleChangePanel =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {/* Modal das 3 abas */}
      <Modal
        open={isOverlayModalVisible}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <h1>{t<string>('overlay-pontos.title')}</h1>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '95%', alignItems: 'center' }}>
              <Tabs sx={{ width: '95%' }} value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab label={t<string>('overlay-pontos.overlay')} value="0" />
                <Tab label={t<string>('overlay-pontos.filter')} value="1" />
                <Tab label={t<string>('overlay-pontos.thematic')} value="2" />
              </Tabs>
            </Box>

            {/*Aba 1 de overlays*/}
            <div className={classes.scrollFilter}>
              <TabPanel value='0' className="tabPanel">

                <div className={classes.divTab}>
                  {overlays.map((overlay: any, index: any) => (
                    <div key={overlay.id} className={classes.marginBottom}>
                      <Accordion
                        expanded={expanded === `panel${index}`} onChange={handleChangePanel(`panel${index}`)}
                        key={overlay.id}
                      >
                        <AccordionSummary
                          aria-controls={`panel${index}d-content`}
                          id={`panel${index}d-content`}
                        >
                          <Typography component={'span'}>{overlay.overlay}</Typography>
                        </AccordionSummary>
                        <div className={classes.scrollFilter} >
                          {/* <TextField
                        id='outlined-basic'
                        label='Ordem de exibição'
                        variant='outlined'
                        type='number'
                      /> */}
                          <div key={overlay.id} className={classes.between}>
                            <div className={classes.center}>
                              <Checkbox onClick={(e) => handleChangeCheckedAllLayers(e, overlay.id)}></Checkbox>
                              <label>Select/Deselect All</label>
                            </div>
                            <TextField className={classes.order} type='number' onChange={(e) => handleChangeOrderOverlays(e, index)} label='Ordem de Exibição (opcional)'></TextField>
                          </div>

                          <AccordionDetails>
                            <Typography component={'span'}>
                              <FormGroup>
                                {overlay.layers.map((layer: any) => (
                                  <div className={classes.center} key={layer.id}>
                                    <Checkbox {...label}
                                              onChange={(e) => handleChangeCheckedLayers(e, layer)}
                                              checked={!!overlayPontosChecked.overlays.find((item: any) => item === layer.id)}
                                    />
                                    {layer.iconUrl ?
                                      <img className={classes.iconLayer} src={layer.iconUrl} />
                                      : <></>}
                                    <label>{layer.description}</label>
                                  </div>
                                  // <FormControlLabel
                                  //   key={layer.id}
                                  //   control={<Checkbox />}
                                  //   label={layer.description}
                                  //   // onChange={handleCheck}
                                  //   onChange={(e) => getCartoPoints(e, layer.column, layer.value, overlay.dataset.dataset)}
                                  // />
                                ))}
                              </FormGroup>
                            </Typography>
                          </AccordionDetails>
                        </div>
                      </Accordion>
                    </div>
                  ))}
                </div>
                <Box className={classes.fixarRodape}>
                  {loadingOverlay ?
                    <Button
                      disabled
                      variant='contained'
                    >
                      <CircularProgress size='15px' sx={{ marginRight: "10px" }} /> {t<string>('overlay-pontos.apply')}
                    </Button>
                    :
                    <Button
                      onClick={() => applyLayersToMap()}
                      variant='contained'
                    >
                      {t<string>('overlay-pontos.apply')}
                    </Button>
                  }
                </Box>

              </TabPanel>
            </div>

            {/*Aba 2 de filtros*/}
            <TabPanel key={uuidv4()} value='1'>
              <div key={uuidv4()} className={classes.divTab}>

                {overlays.map((overlay: any, index: any) => (
                  <div key={overlay.id} className={classes.marginBottom}>

                    <Accordion
                      expanded={expanded === `panel${index}`} onChange={handleChangePanel(`panel${index}`)}
                      key={overlay.id}
                    >
                      <AccordionSummary
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                      >
                        <Typography component={'span'}>{overlay.overlay}</Typography>
                      </AccordionSummary>
                      <div className={classes.center}>
                        <IconButton onClick={() => handleAddFilterOverlay(overlay)}><AddIcon /></IconButton>
                        <label>Novo</label>
                      </div>
                      <AccordionDetails>
                        {!overlay.filters ?
                          <div className={classes.centerMax}>
                            <label>Não existem filtros criados para essa geografia</label>
                          </div>
                          : (overlay.filters.map((overlayFilter: any, index: any) => (
                            <div key={overlayFilter.id} className={classes.between}
                            >
                              <div className={classes.rowLeft}>
                                <Checkbox {...label}
                                  onChange={(e) => handleChangeCheckedFilters(e, overlayFilter)}
                                          checked={!!overlayPontosChecked.filters.find((item: any) => item === overlayFilter.id)}
                                />
                                <label >{overlayFilter.name}</label>
                              </div>
                              <div className={classes.row}>
                                <IconButton onClick={() => handleEditFilterOverlay(index, overlayFilter, overlay)}><EditIcon /></IconButton>
                                <IconButton onClick={() => handleDeleteFilterOverlay(index, overlayFilter, overlay)}><DeleteIcon /></IconButton>
                              </div>
                              {/* <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={selectedFiltersOverlayTest}
                                onChange={(e) => handleChangeRadioFilters(overlayFilter)}
                              >
                                <div key={overlayFilter.id} className={classes.between}>
                                  <FormControlLabel value={overlayFilter} control={<Radio />} label={overlayFilter.name} />
                                  <div className={classes.row}>
                                    <IconButton onClick={() => handleEditFilterOverlay(index, overlayFilter, overlay)}><EditIcon /></IconButton>
                                    <IconButton onClick={() => handleDeleteFilterOverlay(index, overlayFilter, overlay)}><DeleteIcon /></IconButton>
                                  </div>
                                </div>
                              </RadioGroup> */}
                            </div>
                          ))
                          )
                        }
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
              <Box className={classes.fixarRodape}>
                <Button
                  onClick={() => {
                    applyLayersToMap();
                  }}
                  variant='contained'
                >
                  {t<string>('overlay-pontos.apply')}
                </Button>
              </Box>
            </TabPanel>

            {/*Aba 3 de tematicos*/}
            <TabPanel key={uuidv4()} value='2' className="tabPanel">
              <div className={classes.divTab}>

                {overlays.map((overlay: any, index: any) => (
                  <div key={overlay.id} className={classes.marginBottom}>
                    <Accordion
                    expanded={expanded === `panel${index}`} onChange={handleChangePanel(`panel${index}`)}
                    key={overlay.id}
                    >
                      <AccordionSummary
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                      >
                        <Typography component={'span'}>{overlay.overlay}</Typography>
                      </AccordionSummary>
                      <div className={classes.center}>
                        <IconButton onClick={() => {
                          handleOpenAddTematic(overlay)
                          console.log(overlay.tematics)
                        }}><AddIcon /></IconButton>
                        <label>Novo</label>
                      </div>
                      <div className={classes.scrollFilter} >
                        {/* <TextField
                        id='outlined-basic'
                        label='Ordem de exibição'
                        variant='outlined'
                        type='number'
                      /> */}
                        <AccordionDetails>
                          <Typography component={'span'}>
                            {userTematics.length === 0 ?
                              <div className={classes.centerMax}>
                                <h4>
                                  Não existem temáticos criados para essa geografia
                                </h4>
                              </div>
                              : <>
                                {userTematics.map((thematic: any, index: any) => (
                                  overlay.id === thematic.overlay.id ?
                                    <div key={thematic.id} className={classes.between}
                                    >
                                      <div className={classes.rowLeft}>
                                        <Checkbox {...label}
                                          onChange={(e) => handleChangeCheckedThematic(e, thematic)}
                                          checked={!!overlayPontosChecked.tematicos.find((item: any) => item === thematic.id)}
                                        />
                                        <label >{thematic.name}</label>
                                      </div>
                                      <div className={classes.row}>
                                        <IconButton onClick={() => handleEditFilterThematic(index, thematic)}><EditIcon /></IconButton>
                                        <IconButton onClick={() => handleDeleteFilterThematic(index, thematic)}><DeleteIcon /></IconButton>
                                      </div>
                                    </div>
                                    : null
                                ))}
                              </>
                            }
                          </Typography>
                        </AccordionDetails>
                      </div>
                    </Accordion>
                  </div>
                ))}
              </div>

              <Box className={classes.fixarRodape}>
                <Button
                  onClick={() => {
                    applyLayersToMap();
                  }}
                  variant='contained'
                >
                  {t<string>('overlay-pontos.apply')}
                </Button>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>

      {/* Modal de criar / editar filtro */}
      <Modal
        open={openAddFilterOverlay}
        onClose={handleCloseAddFilterOverlay}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={classes.title}>
            <Typography id='modal-modal-title' variant='h6' component={'span'}>
              {t<string>('overlay-pontos.title')}
            </Typography>
          </div>

          <TextField value={addNameFilter} onChange={(e) => {
            setAddNameFilter(e.target.value)
          }} className={classes.textName} label={t<string>('area-overlay.name')}></TextField>


          <Box className={classes.columnOver}>
            <div className={classes.rowBeet}>
              <strong><h2>{t<string>('area-overlay.variables')}</h2></strong>
              <IconButton //disabled={filterVariable.length?true:false}
                onClick={addVariablesFilter}
              ><AddIcon /></IconButton>

            </div>

            <div>
              {filterVariable.map((variable: any, index: any) => (
                <FormControl key={index} fullWidth>
                  <div className={classes.divFilter}>
                    {index > 0 ?
                      <TextField
                        select
                        className={classes.textLabelsub}
                        value={variable.conjuct}
                        label='Subcondição'
                        defaultValue=' AND '
                        onChange={(e) => handleChangeVariableFilterSubCondition(index, e.target.value)}
                        variant='standard'
                      >
                        <MenuItem value={' AND '}> e </MenuItem>
                        <MenuItem value={' OR '}> ou </MenuItem>
                      </TextField>
                      : <></>}
                    <TextField
                      select
                      className={classes.textLabel}
                      value={variable.variable}
                      label='Variaveis'
                      onChange={(e) => handleChangeVariableFilterVariable(index, e.target.value)}
                      variant='standard'
                    >
                      {variablesFilter.map((filter: any, index: any) => (
                        <MenuItem key={index} value={filter.variable.column}> {filter.variable.label} </MenuItem>
                      ))}
                    </TextField>
                    {typeVarOverlayFilter === 'STRING' ?
                      <TextField
                        select
                        disabled={variable.variable !== '' ? false : true}
                        className={classes.textLabel}
                        value={variable.condition}
                        label='Condição'
                        onChange={(e) => handleChangeVariableFilterCondition(index, e.target.value)}
                        variant='standard'
                      >
                        <MenuItem value='='>igual</MenuItem>
                        <MenuItem value=' LIKE '>contém</MenuItem>
                        <MenuItem value=' NOT LIKE '>não contém</MenuItem>
                        <MenuItem value='<>'>diferente</MenuItem>
                      </TextField>
                      :
                      <TextField
                        select
                        disabled={variable.variable !== '' ? false : true}
                        className={classes.textLabel}
                        value={variable.condition}
                        label='Condição'
                        onChange={(e) => handleChangeVariableFilterCondition(index, e.target.value)}
                        variant='standard'
                      >
                        <MenuItem value='='>=({t<string>('overlay-pontos.equal')})</MenuItem>
                        <MenuItem value='!='>≠({t<string>('overlay-pontos.not-equal')})</MenuItem>
                        <MenuItem value='<='>≤ ({t<string>('overlay-pontos.less-equal')})</MenuItem>
                        <MenuItem value='<'>`{'<'}`({t<string>('overlay-pontos.less')})</MenuItem>
                        <MenuItem value='>='>≥ ({t<string>('overlay-pontos.greater-equal')})</MenuItem>
                        <MenuItem value='>'>`{'>'}`({t<string>('overlay-pontos.greater')})</MenuItem>
                      </TextField>
                    }
                    <TextField
                      className={classes.textCompany}
                      disabled={variable.variable !== '' ? false : true}
                      value={variable.value}
                      label='Valor'
                      onChange={(e) => handleChangeVariableFilterValue(index, e.target.value)}
                      variant='standard'
                    />
                    <br />
                    <IconButton
                      className={classes.buttonIcon}
                      size='small'
                      aria-label='delete'
                      color='primary'
                      onClick={() => removeVariablesFilter(index)}
                    >
                      <BackspaceIcon />
                    </IconButton>
                  </div>
                </FormControl>
              ))}
            </div>
          </Box>

          <Box className={classes.fixarRodape}>
            <SaveButton onClick={saveAddFilterOverlay}
              style={{ marginRight: '10px' }} >{t<string>('global.save')}</SaveButton>
            <CancelButton onClick={handleCloseAddFilterOverlay} >{t<string>('global.cancel')}</CancelButton>
          </Box>
        </Box>
      </Modal>

      {/* Modal de criar / editar tematico */}
      <Modal
        open={openAddTematic}
        onClose={handleCloseAddTematic}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={classes.title}>
            <Typography id='modal-modal-title' variant='h6' component='h1'>
              {t<string>('overlay-pontos.title')}
            </Typography>
          </div>

          <div className={classes.margin5}>
            <div>
              <TextField className={classes.textTematics30} variant='standard' id="outlined-basic" label={t<string>('localization.name')}
                         onChange={(e) => setName(e.target.value)}
                         value={name} />
            </div>
            <br />
            <br />
            <div className={classes.row}>
              <Switch
                checked={checkBoxTypeColor}
                onChange={(e) => handleChangeSwitchChecked(e)}
                inputProps={{ 'aria-label': 'controlled' }}
              />

              <TextField
                select
                disabled={!(tematicoInsertDTO.type === THEMATIC_TYPE.COLOR || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE)}
                className={variableColorSettings?.column != '' && (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE) ? classes.textTematics : classes.textTematics30}
                id="demo-simple-select"
                variant='standard'
                value={selectLabelTypeColor}
                label={t<string>('overlay-pontos.group-by-color')}
                //onChange={(e) => handleChangeColorGroup(e)}
              >
                {variablesTematics?.map((element: any) => (
                  <MenuItem key={element.variable} value={element.variable?.label}
                            onSelect={(e) => setFilt(element.variable?.type)}
                            onClick={() => handleChangeColorGroup(element.variable)}
                            // selected={selectLabelTypeColor === element.variable.id}
                  >{element.variable?.label}</MenuItem>
                ))}
              </TextField>

              {variableColorSettings?.column != '' && (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE) ?
                <TextField
                  select
                  disabled={lengthColorGroup.length === 0 ? true : false}
                  className={classes.textTematics}
                  id="demo-simple-select"
                  variant='standard'
                  value={nGroupColor}
                  label={t<string>('overlay-pontos.No-of-Groups')}
                >
                  {lengthColorGroup.map((item: any) => (
                    <MenuItem key={item} value={item}
                      onClick={() => handleChangeNgroup(item)}
                    >{item}</MenuItem>
                  ))}

                </TextField>
                : <></>}
            </div>
            {+nGroupColor >= 2 && (tematicoInsertDTO.type === THEMATIC_TYPE.COLOR || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE) ?
              <>
                {typeTematics === 'FLOAT64' || typeTematics === 'INT64' || typeTematics === 'NUMERIC' ?
                  <Box>
                    <TextField
                      disabled
                      className={classes.textName}
                      type='text'
                      variant='standard' />
                    <TextField
                      className={classes.textNumber}
                      label="De"
                      type='number'
                      variant='standard'
                      value={inGroupColor}
                      onChange={(e) => setInGroupColor(+e.target.value)}
                    />
                    <SaveButton onClick={() => calculateMinMax('color', nGroupColor)}>calcular Min Max</SaveButton>
                    <div className={classes.textName} />
                    {groupColor.map((item: any, index: any) => (
                      <Box key={item.id} className={classes.column}>
                        <TextField
                          className={classes.textName}
                          label={t<string>('overlay-pontos.name')}
                          type='text'
                          variant='standard'
                          value={item.name}
                          onChange={(e) => handleChangeName(index, e.target.value)} />
                        <TextField
                          className={classes.textNumber}
                          label='Até'
                          type='number'
                          variant='standard'
                          value={item.condition}
                          onChange={(e) => handleChangeConditionGC(index, e.target.value)} />
                        <TextField
                          className={classes.textColor}
                          type='color'
                          variant='standard'
                          value={item.color}
                          onChange={(e) => handleChangeColor(index, e.target.value)} />
                      </Box>
                    ))}
                  </Box>
                  :
                  <div>
                    {groupColor.map((item: any, index: any) => (
                      <Box className={classes.column}>
                        <TextField
                          disabled
                          className={classes.textName}
                          label={t<string>('overlay-pontos.name')}
                          type='text'
                          variant='standard'
                          value={item.name} />
                        <TextField
                          className={classes.textColor}
                          type='color'
                          variant='standard'
                          value={item.color}
                          onChange={(e) => handleChangeColorString(index, e.target.value)} />
                      </Box>
                    ))}
                  </div>
                }
              </>
              :
              <></>}
            <br />
            <hr />
            <br />
            <div className={classes.row}>
              <Switch
                checked={checkBoxTypeSize}
                onChange={(e) => handleChangeSwitchTwoChecked(e)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <TextField
                select
                disabled={!(tematicoInsertDTO.type === THEMATIC_TYPE.SIZE || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE)}
                className={classes.textTematics}
                id="demo-simple-select"
                variant='standard'
                value={tematicoInsertDTO.sizeSettings.variable.label}
                label={t<string>('overlay-pontos.Group-by-size')}
                //onChange={(e) => handleChangeSizeGroup(e)}
              >
                {variablesTematicsNumber.map((element: any) => (
                  <MenuItem value={element.variable?.label}
                            onSelect={(e) => setFilt(element.variable?.type)}
                            onClick={() => handleChangeSizeGroup(element.variable)}
                            // selected={tematicoInsertDTO.sizeSettings.variable.id === element.variable.id}
                  >{element.variable?.label}</MenuItem>
                ))}
              </TextField>

              <TextField
                select
                disabled={variableSizeSettings.column === '' ? true : false}
                className={classes.textTematics}
                id="demo-simple-select"
                variant='standard'
                value={classifiMethod}
                label={t<string>('overlay-pontos.Method-of-classification')}
                onChange={(e) => handleChangeClassifiMethod(e)}
              >
                <MenuItem value={'Jenks'}>Jenks</MenuItem>
                <MenuItem value={'Quantiles'}>Quantiles</MenuItem>
                <MenuItem value={'Heads/Tails'}>Heads/Tails</MenuItem>
                <MenuItem value={'Equal'}>Equal</MenuItem>
                <MenuItem value={'Custom'}>Customizado</MenuItem>
              </TextField>
              <TextField
                select
                disabled={classifiMethod === '' ? true : false}
                className={classes.textTematics}
                variant='standard'
                id="demo-simple-select"
                value={ngroupSize}
                label={t<string>('overlay-pontos.No-of-Groups')}
                onChange={(e) => handleChangeNgroupSize(e)}
              >
                <MenuItem key={2} value={2}>2</MenuItem>
                <MenuItem key={3} value={3}>3</MenuItem>
                <MenuItem key={4} value={4}>4</MenuItem>
                <MenuItem key={5} value={5}>5</MenuItem>
                <MenuItem key={6} value={6}>6</MenuItem>
                <MenuItem key={7} value={7}>7</MenuItem>
                <MenuItem key={8} value={8}>8</MenuItem>
                <MenuItem key={9} value={9}>9</MenuItem>
              </TextField>
            </div>
            <br />
            <hr />
            <br />
            {(tematicoInsertDTO.type !== '') && (variableSizeSettings.column !== '' || variableColorSettings.column !== '')? (
              <>
                <Box className={classes.row}>
                  {variableSizeSettings !== "" && tematicoInsertDTO.type === THEMATIC_TYPE.SIZE || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE ?
                    <TextField id="outlined-basic" className={classes.textTematics30} label='color' variant='standard' type='color'
                               value={colorTematic}
                               onChange={(e) => handleChangeColorTematics(e)} />
                    : <></>}
                  <TextField id="outlined-basic" className={classes.textTematics} inputProps={{ min: 0, max: 10 }} label={t<string>('overlay-pontos.opacity')} variant='standard' type='number'
                             value={opacity}
                             onChange={(e) => handleChangeOpacity(e)} />
                  <TextField id="outlined-basic" className={classes.textTematics30} label={t<string>('overlay-pontos.border-color')} variant='standard' type='color'
                             value={colorBorder}
                             onChange={(e) => handleChangeColorBorder(e)} />
                  <TextField id="outlined-basic" className={classes.textTematics} inputProps={{ min: 0, max: 10 }} label={t<string>('overlay-pontos.edge-opacity')} variant='standard' type='number'
                             value={opacityBorder}
                             onChange={(e) => handleChangeOpacityBorder(e)} />
                  <TextField id="outlined-basic" className={classes.textTematics} inputProps={{ min: 0, max: 10 }} label={t<string>('overlay-pontos.edge-thickness')} variant='standard' type='number'
                             value={thicknessBorder}
                             onChange={(e) => handleChangeThicknessBorder(e)} />
                </Box>
                <br/>
                <hr/>
                <br/>
              </>
            ) : (
              <>
              </>
            )
            }
            {tematicoInsertDTO.type === THEMATIC_TYPE.SIZE || tematicoInsertDTO.type === THEMATIC_TYPE.COLOR_SIZE && classifiMethod !== '' ? (
              <>
                {classifiMethod === 'Custom' ?
                  (ngroupSize !== '1' ?

                    <div className={classes.rowCircle}>
                      <TextField className={classes.textTematics10} style={{ margin: '5px' }} label='De'
                                 value={inGroupCircle}
                                 onChange={(e) => setInGroupCircle(+e.target.value)} type='number'></TextField>

                      <SaveButton onClick={() => calculateMinMax('size', ngroupSize)}>calcular Min Max</SaveButton>

                      {groupCircle.map((circle: any, index) =>
                        <>
                          <div className={classes.divCircle}>
                            <div style={{
                              width: `${circle.radius}px`,
                              height: `${circle.radius}px`,
                              borderRadius: '50%',
                              backgroundColor: 'red',
                              margin: '50px',
                              opacity: `${opacity / 10}`,
                              border: `${thicknessBorder}px solid rgba(0, 0, 0, ${opacityBorder / 10})`,
                              //borderWidth: `${thicknessBorder}px`,
                              //borderColor: `${colorBorder}`,
                            }} />
                            <TextField style={{ margin: '5px' }} label='até' type='number' inputProps={{ min: 10 }}
                                       defaultValue='0'
                                       value={circle.condition}
                                       onChange={(e) => handleChangeConditionTematics(e, index)}></TextField>
                            <TextField style={{ margin: '5px' }} label='raio' type='number' inputProps={{ min: 10, max: 100 }}
                                       defaultValue='10'
                                       value={circle.radius}
                                       onChange={(e) => handleChangeRadiusTematics(e, index)}></TextField>
                          </div>
                        </>
                      )}
                    </div>
                    : <></>)
                  :
                  <Box className={classes.row}>
                    <Box className={classes.columnC}>
                      <div style={{
                        width: `${minWidth}px`,
                        height: `${minWidth}px`,
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        margin: '50px',
                        opacity: `${opacity / 10}`,
                        border: `${thicknessBorder}px solid rgba(0, 0, 0, ${opacityBorder / 10})`,
                        //borderWidth: `${thicknessBorder}px`,
                        //borderColor: `${colorBorder}`,
                      }} />

                      <TextField
                        className={classes.textTam}
                        label={t<string>("overlay-pontos.minimum-size")}
                        type='number'
                        inputProps={{ min: 10, max: 100 }}
                        value={minWidth}
                        onChange={(e) => changeMinWidth(e)} />
                    </Box>

                    <Box className={classes.columnC}>
                      <div style={{
                        width: `${maxWidth}px`,
                        height: `${maxWidth}px`,
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        margin: '50px',
                        opacity: `${opacity / 10}`,
                        border: `${thicknessBorder}px solid rgba(0, 0, 0, ${opacityBorder / 10})`,
                        //borderWidth: `${thicknessBorder}px`,
                        //borderColor: `${colorBorder}`,
                      }} />

                      <TextField
                        className={classes.textTam}
                        label={t<string>("overlay-pontos.maximum-size")}
                        type='number'
                        inputProps={{ min: 10, max: 100 }}
                        value={maxWidth}
                        onChange={(e) => changeMaxWidth(e)} />
                    </Box>
                  </Box>
                }

              </>
            ) : <></>}
          </div>

          <Box className={classes.fixarRodape}>
            <SaveButton onClick={clickSaveTematics}>{t<string>('global.save')}</SaveButton>
            <CancelButton onClick={handleCloseAddTematic}>{t<string>('global.cancel')}</CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

