import * as React from 'react';
import { MouseEvent, useEffect, useState } from 'react';
import {
  AppBar,
  Drawer,
  Divider,
  Hidden,
  Grid,
  IconButton,
  Tabs,
  Toolbar,
  Link,
  makeStyles,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
  Box,
  Modal
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as CartoLogo } from 'assets/img/carto-logo.svg';
import { ReactComponent as CartoLogoXS } from 'assets/img/carto-logo-xs.svg';
import { ROUTE_PATHS } from 'routes';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { CustomTheme } from 'theme';
import {
  addCallbackToSetCoordinates,
  addDrawLayer, addMyLocationLayer,
  addRaioLayer,
  clearLayers,
  removeLayerById, setCoordinates,
} from '../../store/mapSlice';
import { setLegendColors } from 'store/appSlice';
import {useDispatch, useSelector} from "react-redux";
import { userApi } from "./../../service/http-out/user";
import { CompanyApi } from 'service/http-out/company';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { AccountCircle, FolderOpen, Help, LayersClear, Logout, PushPin } from '@mui/icons-material';
import { GlobalContext } from '../../contexts/globalContext';
import { setViewState } from '@carto/react-redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core'
import Basemap from "../views/client/Basemap/Basemap";
import UrlHelp from 'components/views/client/UrlHelp/UrlHelp';
import {RootState} from "../../store/store";
import { bgcolor } from '@mui/system';
import { THEMATIC_TYPE } from '../modal/Overlay';
import { DRAW_LAYER_ID } from '../layers/GDMEditableGeojsonLayer';

//@ts-ignore
// const { overlayPontosChecked, setOverlayPontosChecked } = React.useContext(GlobalContext);

const useStylesCommon = makeStyles((theme) => ({
  title: {
    '& h1': {
      display: 'flex',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.white,

      '& strong': {
        marginRight: theme.spacing(0.5),
      },

      '& svg': {
        height: `${theme.typography.subtitle1.lineHeight}em`,
        marginRight: theme.spacing(1.5),
        width: 'auto',
        verticalAlign: 'bottom',
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'none',
    zIndex: theme.zIndex.modal + 1,
    overflow: 'hidden',
  },
  img: {
    width: '50px',
    height: "50px",
    cursor: "pointer",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position='static' className={classes.header}>
      <Toolbar variant='dense'>
        <Mobile />
        <Desktop />
      </Toolbar>
    </AppBar>
  );
}

const useStylesDesktop = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 3),
  },
  img: {
    cursor: "pointer",
    maxWidth: "150px",
    maxHeight: "45px"
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    //float: 'left',
    width: '100%',
  },
  root2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    //justifyContent: 'space-between',
    //float: 'left',
    width: '30%',
  },
  root3: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    //justifyContent: 'space-between',
    //float: 'left',
    width: '30%',
  },
  margin: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  padding: {
    paddingTop: '30px',
  },
  beetwen: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  right: {
    alignItems: 'right',
  },
  legendColor: {
    width: '30px',
    height: '20px',
    border: '1px solid black',
  },
  centerMax: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    alignContent: 'center'
  },
}));

function Desktop() {
  const navigate = useNavigate();
  const [logo, setLogo] = React.useState('');
  const dispatch = useDispatch();
  //@ts-ignore
  const { setCompanySignedId, setCompanyUserSigned, companyUserSigned, userSignedName, setUserSignedName, setAreasContext, setLocationContext} = React.useContext(GlobalContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const { t, i18n } = useTranslation();
  const legends = useSelector((state: RootState) => state.app.legends);

  useEffect(() => {
    async function decodeToken() {
      let user = {}
      const companyId: any = localStorage.getItem('companyId');
      const token = localStorage.getItem('token');
      const userId: any = localStorage.getItem('id');

      if (token === undefined || token === '') {
        navigate(ROUTE_PATHS.LOGIN);
      }

      // @ts-ignore
      const decoded: any = jwt_decode(token);
      setUserSignedName(localStorage.getItem('name'))

      if (decoded.role == 'admin') {
        setIsAdmin(true);
      } else {
        CompanyApi.getCompanyByuserId(companyId).then((res) => {
          setCompanyUserSigned(res.data)
        }).catch((erro) => {
          console.log(erro.response.data);
          if (erro.response.data.message === "Unauthorized" && erro.response.data.statusCode === 401) {
            navigate(ROUTE_PATHS.LOGIN);
          }
        })

        //   CompanyApi.getCompanyPacksOverlays(companyId).then((res:any) => {
        //     localStorage.setItem('overlays', JSON.stringify(res.data[0].packs));
        //     console.log(res.data[0].packs)
        //     //getPacksCompany(res.data[0].packs)
        //   }).catch((error:any) => {
        //     console.log(error)
        //   })

        //   CompanyApi.getCompanyPacksGeographies(companyId).then((res: any) => {
        //     console.log(res.data[0].packs)
        //     localStorage.setItem('geographies', JSON.stringify(res.data[0].packs));
        //     //getPacksCompany(res.data[0].packs)
        // }).catch((error: any) => {
        //     console.log(error)
        //     //toast.error('Pacotes não contem geografias!')
        // })

        setCompanySignedId(companyId)
        dispatch(setViewState({
          latitude: -23.533773,
          longitude: -46.625290,
          zoom: 8
        }));
      }
      //Chamar api para pegar empresa
      //Empresa na api de context
    }
    decodeToken();
  }, []);

  const classes = {
    ...useStylesCommon(),
    ...useStylesDesktop(),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [isUrlHelpModalVisible, setUrlHelpModalVisible] = useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const [isLegendVisible, setIsLegendVisible] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const showHideLegend = () => {
    console.log(legends)
    if(isLegendVisible) {
      setIsLegendVisible(false)
    } else {
      setIsLegendVisible(true)
    }
  }

  const onCloseLegend = () => {
    setIsLegendVisible(false)
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '30%',
    overflow: 'scroll',
    width: '350px',
    height: '350px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid black',
    p: 4,
  };

  const handleWhereIam = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position);
        dispatch(setViewState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          zoom: 13
        }));
        const data = [
          {
            color: [30,144,255],
            opacity: 0.5,
            raio: 500,
            coordinates: [
              position.coords.longitude,
              position.coords.latitude
            ]
          }
        ];
        dispatch(addMyLocationLayer(data));
      });
  };

  const handleClearMap = () => {
    setLocationContext([])
    setAreasContext([])
    console.log('Clear Map');
    // setOverlayPontosChecked({
    //   overlays: [],
    //   filters: [],
    //   tematicos: [],
    // })
    dispatch(clearLayers());
    dispatch(setLegendColors({}));
  };

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div style={{ width: '100%' }}>
    <Box sx={{ display: { xl: 'none', xs: 'down' } }} className={classes.root}>

      <div className={classes.root3}>
        <Link
          component={NavLink}
          to={ROUTE_PATHS.DEFAULT}
          className={classes.title}
        > <Box sx={{ display: { xl: 'none', xs: 'up' } }}>
            <Typography component='h1' variant='subtitle1' noWrap>
              <AppName />
            </Typography></Box>

        </Link>
        <Divider
          orientation='vertical'
          className={classes.divider}
          light
        ></Divider>

        {/* <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Empresa
          </Button> */}
        {userSignedName === "undefined" ?
          <></> :
          <Box className={classes.root3}>{userSignedName}</Box>
        }
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem className={classes.padding} onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>

      {isAdmin ?
        <Box>
          <IconButton onClick={logout} title='Logout'><Logout sx={{ color: 'white' }} /></IconButton>
        </Box>
        :
        <div >
          <UrlHelp
            isUrlHelpModalVisible={isUrlHelpModalVisible}
            setUrlHelpModalVisible={setUrlHelpModalVisible}
          />
          <Box className={classes.root2}>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <Divider
              orientation='vertical'
              className={classes.divider}
              light
            ></Divider>
            <div className={classes.root}>
              <img className={classes.img} src={logo} />
            </div>
            <div className={classes.root}>
              <Button
                className={classes.root3}
                id="demo-positioned-button"
                aria-controls={open2 ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
                onClick={handleClick2}

              >
                <ViewListIcon sx={{ color: 'white' }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl2}
                className={classes.padding}
                open={open2}
                onClose={handleClose2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {legends.items ?
                  <>
                    <MenuItem onClick={showHideLegend} className={classes.padding}> <IconButton title={t<string>('header.legend')}> <FormatListBulletedIcon /></IconButton> </MenuItem>
                    <MenuItem onClick={handleClearMap} > <IconButton title={t<string>('header.clean')}> <LayersClear /></IconButton> </MenuItem>
                  </>
                :
                  <MenuItem onClick={handleClearMap}  className={classes.padding}> <IconButton title={t<string>('header.clean')}> <LayersClear /></IconButton> </MenuItem>
                }
                <MenuItem onClick={handleWhereIam} > <IconButton title={t<string>('header.where')}> <PushPin /></IconButton></MenuItem>
                <MenuItem onClick={() => setUrlHelpModalVisible(!isUrlHelpModalVisible)} > <IconButton title={t<string>('header.help')}> <Help /></IconButton> </MenuItem>
                <MenuItem > <IconButton title={t<string>('header.project')}> <FolderOpen /></IconButton> </MenuItem>
                <MenuItem > <IconButton title={t<string>('header.password')}> <AccountCircle /></IconButton></MenuItem>
                <MenuItem onClick={logout}> <IconButton title='Logout'> <Logout /></IconButton> </MenuItem>
              </Menu>
            </div>
            <NavigationMenu />
          </Box>
          <Grid container item xs justifyContent='flex-end'>
            <UserMenu />
          </Grid>
        </div>
      }
    </Box>
        <Modal
          open={isLegendVisible}
          onClose={onCloseLegend}
          hideBackdrop
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div style={{  display: 'flex' ,justifyContent: 'space-between' }}>
              <h3>Legenda</h3>
              <IconButton onClick={() => onCloseLegend()}><CloseIcon /></IconButton>
            </div>
            <h3>{legends.name}</h3>
            <hr/>
            {legends?.type === THEMATIC_TYPE.COLOR || legends?.type === THEMATIC_TYPE.COLOR_SIZE ?
              legends?.items?.colorSettings.colorSetting.map((item: any, index: any) =>
                <div style={{ marginTop: '5px' , display: 'flex', flexDirection: 'row'}}>

                  <div className={classes.legendColor} style={{ background: `${item.color}`}}></div>
                  <label style={{ marginLeft: '3px' }}>{item.name}</label>
                </div>
              )
              :
              <></>
            }
            <br/>
            <hr/>
            <br/>

            {legends?.type === THEMATIC_TYPE.SIZE || legends?.type === THEMATIC_TYPE.COLOR_SIZE ?
              legends?.items?.sizeSettings.customCircle.map((item: any, index: any) =>
                <div className={classes.centerMax} style={{ marginTop: '5px' , display: 'flex', flexDirection: 'row'}}>
                  {/*<div style="width: 0.2px; height: 0.2px; border-radius: 50%; background-color: red; margin: 50px; opacity: 1; border: 0.2px solid rgb(0, 0, 0);"></div>*/}
                   <div style={{
                      alignItems:'left',
                     width: `${item.radius}px`,
                     height: `${item.radius}px`,
                     borderRadius: '50%',
                     backgroundColor: 'red',
                     margin: '10px 20px 10px 0px',
                     opacity: `${legends.items.sizeSettings.opacity * 10}`,
                     border: `${legends.items.sizeSettings.thicknessBorder}px solid rgba(0, 0, 0, ${legends.items.sizeSettings.opacityBorder * 10})`,
                   }} />
                  {index === 0 ?
                    <label style={{ marginLeft: '3px' }}>de {legends.items.sizeSettings.customCondition} até {item?.condition}</label>
                  :
                    <label style={{ marginLeft: '3px' }}>de {legends.items.sizeSettings.customCircle[index - 1]?.condition} até {item?.condition}</label>
                  }
                </div>
              )
              :
              <></>
            }
            <br/>
            <hr/>
          </Box>
        </Modal>
    </div>
  );
}

const useStylesMobile = makeStyles((theme) => ({
  menuButton: {
    margin: theme.spacing(0, 0.75, 0, -1.25),

    '& + hr': {
      marginRight: theme.spacing(1.5),
    },
  },
  drawer: {
    minWidth: 260,
  },
}));

function Mobile() {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = {
    ...useStylesCommon(),
    ...useStylesMobile(),
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: { xl: 'none', xs: 'up' } }}>
      <IconButton
        className={classes.menuButton}
        color='inherit'
        aria-label='menu'
        onClick={handleDrawerToggle}
      >
        {drawerOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Divider orientation='vertical' light />
      <Link
        component={NavLink}
        to={ROUTE_PATHS.DEFAULT}
        className={classes.title}
      >
        <Typography component='h1' variant='subtitle1' noWrap>
          <Box sx={{ display: { xl: 'none', xs: 'up' } }}>
            <CartoLogoXS />
            <Divider orientation='vertical' light />
          </Box>

        </Typography>
      </Link>
      <Drawer
        variant='temporary'
        anchor='left'
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Toolbar variant='dense' />
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          item
          xs
        >
          <NavigationMenu column={true} />
        </Grid>
      </Drawer>
    </Box>
  );
}

function AppName() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <strong> {t<string>('header.geography')} </strong>
    </>
  );
}

const useStylesNavigationMenu = makeStyles((theme: CustomTheme) => ({
  navTabs: {
    '& .MuiTabs-indicator': {
      backgroundColor:
        theme.palette.appBar?.contrastText ||
        theme.palette.primary?.contrastText,
    },
  },
}));

function NavigationMenu({ column = false }: { column?: boolean }) {
  const location = useLocation();
  const classes = useStylesNavigationMenu();

  const pathname = location.pathname.split('/')[1] || '';

  return (
    <Grid
      container
      direction={column ? 'column' : 'row'}
      className={!column ? classes.navTabs : ''}
    >
      <Tabs
        value={pathname}
        textColor={column ? 'primary' : 'inherit'}
        orientation={column ? 'vertical' : 'horizontal'}
        variant={column ? 'fullWidth' : 'standard'}
      >
        {/* [hygen] Import links */}
      </Tabs>
    </Grid>
  );
}

const useStylesUserMenu = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    marginLeft: theme.spacing(1),
  },
}));

function UserMenu() {
  const dispatch = useDispatch();
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & (HTMLAnchorElement | HTMLSpanElement)) | null
  >(null);
  const classes = useStylesUserMenu();
  const { t, i18n } = useTranslation();

  // User is NOT logged in, so display nothing
  if (!user) {
    return null;
  }

  // At this point, there is an oauthApp and the user has logged in (forceOAuthLogin mode).
  const open = Boolean(anchorEl);

  const handleMenu = (
    event: MouseEvent<HTMLAnchorElement | HTMLSpanElement>,
  ) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    handleClose();
  };

  // Display User menu, with name, avatar + an attached menu for user-related options
  return (
    <>
      <Link
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        color='inherit'
        onClick={handleMenu}
      >
        <Grid container alignItems='center' item wrap='nowrap'>
          <Box sx={{ display: { xl: 'none', xs: 'down' } }}>
            <Typography variant='caption' color='inherit' noWrap>
              {user.email}
            </Typography>
          </Box>


          <Avatar className={classes.avatar} src={user.picture} />
        </Grid>
      </Link>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>{t<string>('header.logo')}</MenuItem>
        <MenuItem >{t<string>('header.clean')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.where')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.help')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.project')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.help')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.password')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t<string>('header.exit')}</MenuItem>
        <MenuItem>
          <Link href='https://geografiademercado.com/'>{t<string>('header.go')}</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
// function dispatch(arg0: Function) {
//   throw new Error('Function not implemented.');
// }

