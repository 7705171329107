import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 87vh;
  color: #bb8dde;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90vw;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 25px;

  width: 30%;

  outline: none;
  border: 2px solid #d9d9d9;

  ::placeholder {
    color: #c273fe;
  }
`;

export const Select = styled.select`
  padding: 10px;
  font-size: 25px;

  width: 15%;

  outline: none;
  border: 2px solid #d9d9d9;

  color: #c273fe;
`;

export const Option = styled.option`
`;

export const TableContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
`;

export const TableRow = styled.tr``;

export const TableHeader = styled.thead`
  border-bottom: 2px solid #dbdbdb;
  background-color: #043b68;
`;

export const TableHeaderW = styled.thead`
  border-bottom: 2px solid #dbdbdb;
  background-color: #FFFFFF;
`;

export const TableHead = styled.th`
  padding: 1rem;
  text-align: left;
  text-align: center;
  color: white;

  :first-child {
    text-align: start;
  }
`;
export const TableHeadB = styled.th`
  padding: 1rem;
  text-align: left;
  text-align: center;
  color: black;

  :first-child {
    text-align: start;
  }
`;

export const TableBody = styled.tbody`
overflow-y: scroll;
`;

export const TableData = styled.td`
  padding: 1rem;
  text-align: left;
  text-align: center;
  border-bottom: 2px solid #dbdbdb;

  :first-child {
    text-align: start;
  }
`;
export const container = styled.div`
  color: #000000;
  text-align: center;
  padding: 15px;
  margin: 10px auto;
`;

export const SaveButton = styled.a`
  font-weight: 500;
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 110px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1976d2;
  color: #ffffff;
  padding: 6px 16px 6px 16px;
  margin: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  :hover {
    background-color: #1565c0;
  }
`;

export const SaveButtonImage = styled.button`
  font-weight: 500;
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-style: none;
  width: 110px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1976d2;
  color: #ffffff;
  padding: 6px 16px 6px 16px;
  margin: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  :hover {
    background-color: #1565c0;
  }
`;

export const ReportButton = styled.a`
  font-weight: 500;
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 180px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1976d2;
  color: #ffffff;
  padding: 6px 16px 6px 16px;
  margin: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  :hover {
    background-color: #1565c0;
  }
`;

export const CancelButton = styled.a`
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  vertical-align: middle;
  width: 110px;
  border-radius: 4px;
  cursor: pointer;
  background-color: red;
  color: #ffffff;
  padding: 6px 16px 6px 16px;
  margin: 10px;
  :hover {
    background-color: #800000;
  }
`; 
