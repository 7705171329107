// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import {v4 as uuidv4} from 'uuid';

export const OVERLAY_GEOJSON_LAYER_ID = 'overlay-geojson-layer';
export default function GdmGeoJsonLayer(geoJsonData: any, thematic: any, id: string) {
  console.log(thematic);
  let type = 'color';
  if (thematic.sizeSettings.customCircle.length > 0) {
    type = 'size';
  }
  function rgb(hex: any) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)] : null;
  }

  function getColorItem(d: any) {
    if (type === 'size') {
      return rgb('#000000');
    }
    let closest
    if (thematic.colorSettings.variable.type?.toUpperCase() === 'STRING') {
      closest = thematic.colorSettings.colorSetting.find((item: any) => item.condition.toLowerCase() === d.properties[thematic.colorSettings.variable.column].toLowerCase());
    } else {
      closest = thematic.colorSettings.colorSetting.reduce(function(prev: any, curr: any) {
        return (Math.abs(curr.condition - d.properties[thematic.colorSettings.variable.column]) < Math.abs(prev.condition - d.properties[thematic.colorSettings.variable.column]) ? curr : prev);
      });
    }
    if (!closest) {
      closest = {color: '#000000'}
    }
    return rgb(closest.color)
  }

  function getRadius(d: any) {
    return d.properties.circle_size;
  }

  return new GeoJsonLayer({
    id: id +'-'+ uuidv4(),
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    pointType: 'circle',
    // lineWidthScale: 10,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 2,
    getPointRadius: (d: any) => getRadius(d),
    // getPointRadius: 100,
    // getLineWidth: 1,
    // getElevation: 1,
    pointRadiusMinPixels: thematic.sizeSettings.circleMinWidth,
    pointRadiusMaxPixels: type == 'color' ? thematic.sizeSettings.circleMaxWidth : 100,
    //@ts-ignore
    getFillColor: (d: any) => getColorItem(d),
    //@ts-ignore
    getLineColor: rgb(thematic.sizeSettings.borderColor),
    opacity: thematic.sizeSettings.opacity,

    onClick: (info: any, event: any) => {
      console.log('Clicked:', info.object);
      console.log('Clicked:', event);
      if (info?.object) {
        info.object = {
          html: `
ÁREA BRUTA LOCÁVEL (ABL M²)
BAIRRO
CENTRO
</BR>
CEP
45985204
</BR>
DESCRIÇÃO
Shoppings Center
</BR>
ENDEREÇO
PC HILTON CHICON 231
</BR>
MUNICÍPIO
TEIXEIRA DE FREITAS
</BR>
NOME
TEIXEIRA MALL
</BR>
TOTAL DE LOJAS
72
</BR>
SITE
<a href="http:\\\\www.shoppingteixeiramall.com.br/shopping/" target="_blank">Clique para abrir</a>
</BR>
ÁREA BRUTA LOCÁVEL (ABL M²)
BAIRRO
CENTRO
</BR>
CEP
45985204
</BR>
DESCRIÇÃO
Shoppings Center
</BR>
ENDEREÇO
PC HILTON CHICON 231
</BR>
MUNICÍPIO
TEIXEIRA DE FREITAS
</BR>
NOME
TEIXEIRA MALL
</BR>
TOTAL DE LOJAS
72
</BR>
SITE
<a href="http:\\\\www.shoppingteixeiramall.com.br/shopping/" target="_blank">Clique para abrir</a>
</BR>
`
            + info.object?.properties + ' - 44444',
          style: {},
        };
      }
    },
  });
}

