// @ts-ignore
import {GeoJsonLayer, IconLayer, ScatterplotLayer} from '@deck.gl/layers';
import {v4 as uuidv4} from 'uuid';
import { THEMATIC_TYPE } from '../modal/Overlay';

export const OVERLAY_TEMATIC_LAYER_ID = 'overlay-tematic-layer';
export default function TematicoLayer(geoJsonData: any, thematic: any, id: string) {
  console.log(thematic);
  function rgb(hex: any) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16),
      parseInt(result[3], 16)] : null;
  }

  function getColorItem(d: any) {
    if (thematic.type === THEMATIC_TYPE.SIZE) {
      return rgb(thematic.sizeSettings.colorTematic);
    }
    let closest
    if (thematic.colorSettings.variable.type?.toUpperCase() === 'STRING') {
      closest = thematic.colorSettings.colorSetting.find((item: any) =>
        item.condition.toLowerCase() === d.properties[thematic.colorSettings.variable.column].toLowerCase());
    } else {
      closest = thematic.colorSettings.colorSetting.reduce(function(prev: any, curr: any) {
        return (Math.abs(curr.condition - d.properties[thematic.colorSettings.variable.column])
        < Math.abs(prev.condition - d.properties[thematic.colorSettings.variable.column])
          ? curr : prev);
      });
    }

    return rgb(closest.color)
  }

  return new ScatterplotLayer({
    id: id +'-'+ uuidv4(),
    data: geoJsonData.features,
    radiusUnits: 'pixels',
    radiusScale: 50,
    lineWidthScale: 2,
    pickable: true,
    stroked: true,
    filled: true,
    getRadius: (d: any) => d.properties.circle_size / 100,
    getPosition: (d: any) => d.geometry.coordinates,
    //@ts-ignore
    getFillColor: (d: any) => getColorItem(d),
    //@ts-ignore
    getLineColor: rgb(thematic.sizeSettings.borderColor),
    getLineWidth: thematic.sizeSettings.thicknessBorder / 10,
    opacity: thematic.sizeSettings.opacity / 10,
    // opacity: thematic.sizeSettings.opacityBorder / 10,

    onClick: (info: any, event: any) => {
      console.log('Clicked:', info.object);
      console.log('Clicked:', event);
      if (info?.object) {
        info.object = {
          html:
          `<div>
            Content ABCDEFGHIJKL ashghasjdgaskdhsakdhB<br />
            <a href="https://www.google.com">Link para o google</a>
            <button onClick={() => setToggle(!toggle)}>Toggle</button>
          </div>`,
          // html: `ÁREA BRUTA LOCÁVEL (ABL M²)
          //         72
          //         </BR>
          //         SITE
          //         <a href="http://www.shoppingteixeiramall.com.br/shopping/"
          //         target="_blank">Clique para abrir</a>
          //         </BR>${info.object?.properties}  - teste properties `,
          style: {},
        };
      }
    },
  });
}

