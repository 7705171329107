import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import {PolygonLayer} from "@deck.gl/layers";

export const DESENHO_A_MAO_LIVRE_READONLY_LAYER_ID = 'desenho-a-mao-livre-readonly-layer';
function rgb(hex: any) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export default function DesenhoAMaoLivreReadOnlyLayer(myData: any, layer: any) {
  console.log('myData', myData);
  console.log('layer', layer);
  return new PolygonLayer({
    id: DESENHO_A_MAO_LIVRE_READONLY_LAYER_ID +'-'+ uuidv4(),
    data: myData,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: (d: any) => d.contour,
    // @ts-ignore
    getFillColor: (d: any) => rgb(layer.influenceAreas[0].color),
    opacity: layer.influenceAreas[0].opacity / 10,
    getLineColor: [80, 80, 80],
    getLineWidth: 1
  });
}
