import { useRoutes } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import theme from './theme';
import routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import   GlobalContextProvider   from './contexts/globalContext';

const useStyles = makeStyles(() => ({
  app: {
    flex: '1 1 auto',
  },
}));

export default function App() {
  const routing = useRoutes(routes);
  const classes = useStyles();

  return (
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container direction='column' className={classes.app}>
          <LazyLoadRoute>{routing}</LazyLoadRoute>
        </Grid>
        <ToastContainer autoClose={3000} />
      </ThemeProvider>
    </GlobalContextProvider>
  );
}
