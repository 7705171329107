import api from "./http-client";

export const V2ReportApi = {
  getReports: async () => {
    return await api.get('/carto-report');
  }
}

export const CartoApi = {

  listDatasets: async () => {
    return await api.get('/carto-table', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  listDatasetColumnsData: async (table: string) => {
    return await api.get(`/carto-column/${table}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  listLocale: async (obj: any) => {
    return await api.post(`/carto-locale`, obj, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  listLocaleFilters: async (obj: any) => {
    return await api.post(`/carto-filter`, obj, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  listFIlter: async (table: string, column: string, condition: any, value: any) => {
    return await api.get(`/carto-column/table/${table}/column/${column}/condition/${condition}/value/${value}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
  },

  listColumnValue: async (table: string, column: string) => {
    return await api.get(`/carto/table/${table}/column/${column}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  listColumnData: async () => {
    return await api.get("/carto-column-data/", {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  findAll: async () => {
    return await api.get("/carto/", {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  runPostQuery: async (obj: any) => {
    return await api.post(`/run-query/`, obj, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  },

  runQuery: async (path:string, format: string, query: string) => {
    return await api.get(`/run-query?path=${path}&format=${format}&q=${query}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
  }
}
