// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { v4 as uuidv4 } from 'uuid';

export default function GdmLocalizationLayer(geoJsonData: any, localization: any, id: string) {
  function rgb(hex: any) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  return new GeoJsonLayer({
    id: id +'-'+ uuidv4(),
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    extruded: false,
    pointType: 'polygon',
    // lineWidthScale: 200,
    // lineWidthMinPixels: 200,
    // lineWidthMaxPixels: 200,
    // pointRadiusMinPixels: 200,
    // pointRadiusMaxPixels: 200,
    // getPointRadius: 100,
    // getLineWidth: 100,
    //@ts-ignore
    getFillColor: (d: any) => rgb(localization.color),
    //@ts-ignore
    getLineColor:  (d: any) => rgb(localization.color),
    opacity: localization.opacity ? localization.opacity : 0.5,

    // gettooltip: (d: any) => {
    //   console.log('gettooltip: ', d);
    //   d.properties.nome + ' - ' + d.properties.uf;
    // },
    // const handleTooltip = (info: Tooltip) => {
    //   if (info?.object) {
    //     return {
    //       html: `<div class='content'>${info.object.html}<div class='arrow'></div></div>`,
    //       className: classes.tooltip,
    //       style: {
    //         padding: 0,
    //         background: 'none',
    //       },
    //     };
    //   }
    // },
    // onHover: (info: any) => setHoverInfo(info),
    // onHover: (info: any) => {
    //   console.log('Hovered info: ', info.object);
    //   if (info?.object) {
    //     info.object = {
    //       html:
    //         `<a href="https://geografiademercado.com/">` +
    //         `  <img src='${layer.iconUrl}' />` +
    //         `</a>` +
    //         info.object.properties.nome + ' - ' + info.object.properties.endereco_completo,
    //       style: {},
    //     };
    //   }
    // },
    // onClick: (info: any, event: any) => {
    //   console.log('Clicked:', info.object);
    //   // dispatch(setHoverInfo(info));
    //   if (info?.object) {
    //     info.object = {
    //       html:
    //         '<a href="https://geografiademercado.com/">' +
    //         '  <img src="https://casadoteatro.com.br/wp-content/themes/casadoteatro/images/whatsapp-wrapper.png"/>' +
    //         '</a>' +
    //         info.object.properties.nome + ' - ' + info.object.properties.uf,
    //       style: {},
    //     };
    //   }
    // }
  });
}

