import api from './http-client';

export const OverlaySettingsApi = {
  listDatasets: async () => {
    return await api.get('/carto-table');
  },

  listColumns: async (table: string) => {
    return await api.get(`/carto-column/${table}`);
  },

  listLocale: async (obj: any) => {
    return await api.post(`/carto-locale`, obj);
  },

  listLocaleFilters: async (obj: any) => {
    return await api.post(`/carto-filter`, obj);
  },

  listFIlter: async (table: string, column: string, condition: any, value: any) => {
    return await api.get(`/carto-column/table/${table}/column/${column}/condition/${condition}/value/${value}`)
  },

  listValue: async (table: string, column: string) => {
    return await api.get(`/carto/table/${table}/column/${column}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  queryValue: async (table: string, columnFilter: string, columnReturn: string, values: []) => {
    return await api.post(`/carto/table/${table}/column/${columnFilter}`,
      {
        columnReturn: columnReturn,
        itensFilter: values
    },{
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }
};
