import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Public, Layers, Feed } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import LayersIcon from '@mui/icons-material/Layers';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import GestureIcon from '@mui/icons-material/Gesture';
import FilterListIcon from '@mui/icons-material/FilterList';
import AdjustIcon from '@mui/icons-material/Adjust';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import GroupIcon from '@mui/icons-material/Group';
import BuildIcon from '@mui/icons-material/Build';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import {
  Drawer,
  SwipeableDrawer,
  Fab,
  useMediaQuery,
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { setBottomSheetOpen } from 'store/appSlice';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import { useTheme } from '@material-ui/styles';
import { CustomTheme } from 'theme';
import { RootState } from 'store/store';
import { lazy, useEffect, useState, useContext } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ROUTE_PATHS } from '../../routes';
import Basemap from '../views/client/Basemap/Basemap';
import jwt_decode from 'jwt-decode';
import { GlobalContext } from '../../contexts/globalContext';
import {useTranslation} from "react-i18next";
import * as React from 'react';
import {
  addCallbackToSetCoordinates,
  addDrawLayer,
  removeLayerById,
} from '../../store/mapSlice';
import { DRAW_LAYER_ID } from '../layers/GDMEditableGeojsonLayer';

const Overlay = lazy(() => import('components/modal/Overlay'));
const AreaOverlay = lazy(() => import('components/modal/AreaOverlay'));
const Flashdata = lazy(() => import('components/modal/Flashdata'));
const Area = lazy(() => import('components/modal/Area'));
const Localization = lazy(() => import('components/modal/Localization'));
const Report = lazy(() => import('components/modal/Report'));
const Upload = lazy(() => import('components/modal/Upload'));
const BaseMap = lazy(() => import('components/views/client/Basemap/Basemap'));


export const DRAWER_WIDTH = 350;

const useStyles = makeStyles(({ breakpoints }) => ({
  drawer: {
    position: 'relative',
    flex: '0 0 auto',
    [breakpoints.down('xs')]: {
      height: 95,
    },
    [breakpoints.up('xs')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const { breakpoints }: CustomTheme = useTheme();

  return (
    <nav className={classes.drawer}>
      <LazyLoadRoute>
        <Desktop />
      </LazyLoadRoute>
    </nav>
  );
}

const useStylesDesktop = makeStyles(() => ({
  drawerPaper: {
    width: '20vw',

  },
}));

function Desktop() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStylesDesktop();
  const navigate = useNavigate();
  const [isBasemapModalVisible, setBaseMapModalVisible] = useState(false);
  const [isOverlayModalVisible, setBaseOverlayModalVisible] = useState(false);
  const [isAreaOverlayModalVisible, setBaseAreaOverlayModalVisible] =
    useState(false);
  const [isFlashdataModalVisible, setBaseFlashdataModalVisible] =
    useState(false);
  const [isAreaModalVisible, setBaseAreaModalVisible] = useState(false);
  const [isLocalizationModalVisible, setBaseLocalizationModalVisible] =
    useState(false);
  const [isReportModalVisible, setBaseReportModalVisible] = useState(false);
  const [isUploadModalVisible, setBaseUploadModalVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
    //@ts-ignore
  const { userSignedId, setUserSignedId, companyUserSigned }  = useContext(GlobalContext);

  // INIT relacionados ao popup de localizacao ao criar poligono
  const [coordinates, setCoordinates] = useState([]);
  const [color, setColor] = useState([]);
  const [localizationStateDefault, setLocalizationStateDefault] = useState({
    listing: true, hideAddress: true,

  });

  function callbackToSetCoordinates(coordinatesParam: any, color: any) {
    setCoordinates(coordinatesParam);
    setColor(color);
    localStorage.setItem('localization-coordinates', JSON.stringify(coordinatesParam));
    localizationStateDefault.listing = false;
    localizationStateDefault.hideAddress = false;
    setBaseLocalizationModalVisible(true);
  }

  React.useEffect(() => {
    if (coordinates.length > 0) {
      dispatch(removeLayerById(DRAW_LAYER_ID))
      dispatch(addDrawLayer(coordinates, color))
    }
    () => console.log('unmounting...');
  }, [coordinates]);
  // END relacionados ao popup de localizacao ao criar poligono

  useEffect(() => {
    dispatch(addCallbackToSetCoordinates(callbackToSetCoordinates))
    //TODO pegar os dados do idioma da empresa logada
    const token = localStorage.getItem('token');
    i18n.changeLanguage('pt');
  }, []);

  useEffect(() => {
    async function decodeToken() {
      const token = localStorage.getItem('token');
      // @ts-ignore
      const decoded: any = jwt_decode(token);
      if(decoded.sub !== ''){
        setUserSignedId(decoded.sub)
      }
      if (decoded.role == 'admin') {
        setIsAdmin(true);
        navigate(ROUTE_PATHS.HOME)
      }
      //console.log(decoded)
    }
    decodeToken();
  }, []);


  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant='permanent'
      anchor='left'
      open
    >
      {/* <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' noWrap component='div'>
          Persistent drawer
        </Typography>
      </Toolbar> */}
      <Box sx={{ overflow: 'auto', marginTop: '50px' }}>
        <List>
          {!isAdmin ? (
            <>
              <ListItemButton
                onClick={() => setBaseMapModalVisible(!isBasemapModalVisible)}
              >
                <ListItemIcon>
                  <Public />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.basemap')} />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  setBaseOverlayModalVisible(!isOverlayModalVisible)
                }
              >
                <ListItemIcon>
                  <BubbleChartIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.overlayPoint')} />
              </ListItemButton>

              <ListItemButton
                onClick={() =>
                  setBaseAreaOverlayModalVisible(!isAreaOverlayModalVisible)
                }
              >
                <ListItemIcon>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.area-overlay')} />
              </ListItemButton>

              <ListItemButton
                onClick={() =>
                  setBaseFlashdataModalVisible(!isFlashdataModalVisible)
                }
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.flash-data')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => setBaseAreaModalVisible(!isAreaModalVisible)}
              >
                <ListItemIcon>
                  <PanoramaFishEyeIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.area')} />
              </ListItemButton>

              <ListItemButton
                onClick={() =>
                  setBaseLocalizationModalVisible(!isLocalizationModalVisible)
                }
              >
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.location')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => setBaseReportModalVisible(!isReportModalVisible)}
              >
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.reports')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => setBaseUploadModalVisible(!isUploadModalVisible)}
              >
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.data-upload')} />
              </ListItemButton>

            </>
          ) : (
            <>
              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.HOME);
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.home')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.COMPANYLIST);
                }}
              >
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.company')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.UPLOADIMAGE);
                }}
              >
                <ListItemIcon>
                  <InsertPhotoIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.upload-image')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.PACKAGELIST);
                }}
              >
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.packs')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.VARIABLELIST);
                }}
              >
                <ListItemIcon>
                  <GestureIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.variable')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.FILTERS);
                }}
              >
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.filters')}/>
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.FLASHDATA);
                }}
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.flash-data')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.GEOGRAPHY_LIST);
                }}
              >
                <ListItemIcon>
                  <Public />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.geography')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.OVERLAYLIST);
                }}
              >
                <ListItemIcon>
                  <AdjustIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.overlay')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.BASE_MAP_LIST);
                }}
              >
                <ListItemIcon>
                  <Public />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.basemap')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.CHECKLIST);
                }}
              >
                <ListItemIcon>
                  <BeenhereIcon />
                </ListItemIcon>
                <ListItemText primary='Check-in/out' />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.REPORTLIST);
                }}
              >
                <ListItemIcon>
                  <ShowChartIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.reports')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.USER);
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.user')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.FUNCIONALITIESLIST);
                }}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary={t<string>('sidebar.functionality')} />
              </ListItemButton>


            </>
          )}

          {/* {[
            'Mapa Base22',
            <Overlay />,
            'Overlay de Áreas',
            'Flashdata',
            'Área',
            'Localização',
            'Relatório',
            'Upload de dados',
          ].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}
        </List>
        <BaseMap
          isBasemapModalVisible={isBasemapModalVisible}
          setBaseMapModalVisible={setBaseMapModalVisible}
        />
        <Overlay
          isOverlayModalVisible={isOverlayModalVisible}
          setBaseOverlayModalVisible={setBaseOverlayModalVisible}
        />
        <AreaOverlay
          isAreaOverlayModalVisible={isAreaOverlayModalVisible}
          setBaseAreaOverlayModalVisible={setBaseAreaOverlayModalVisible}
        />
        <Flashdata
          isFlashdataModalVisible={isFlashdataModalVisible}
          setBaseFlashdataModalVisible={setBaseFlashdataModalVisible}
        />
        <Area
          isAreaModalVisible={isAreaModalVisible}
          setBaseAreaModalVisible={setBaseAreaModalVisible}
        />
        <Localization
          isLocalizationModalVisible={isLocalizationModalVisible}
          setBaseLocalizationModalVisible={setBaseLocalizationModalVisible}
          localizationStateDefault={localizationStateDefault}
        />
        <Report
          isReportModalVisible={isReportModalVisible}
          setBaseReportModalVisible={setBaseReportModalVisible}
        />
        <Upload
          isUploadModalVisible={isUploadModalVisible}
          setBaseUploadModalVisible={setBaseUploadModalVisible}
        />
      </Box>
    </Drawer>
  );
}
