import {
  CartoBasemapsNames,
  DARK_MATTER,
  GOOGLE_CUSTOM,
  VOYAGER,
} from '@carto/react-basemaps';
// @ts-ignore
import { API_VERSIONS } from '@deck.gl/carto';
import {
  GOOGLE_HYBRID, GOOGLE_ROADMAP,
  GOOGLE_SATELLITE, POSITRON,
} from '@carto/react-basemaps/src/basemaps/basemaps';

interface State {
  viewState: {
    latitude: number,
    longitude: number,
    zoom: number,
    pitch: number,
    bearing: number,
    dragRotate: boolean,
  },
  basemap: CartoBasemapsNames,
  credentials: {
    apiVersion: string,
    apiBaseUrl: string,
    accessToken: string,
  },
  googleApiKey: string, // only required when using a Google Basemap,
  googleMapId: string, // only required when using a Google Custom Basemap
}

export const initialState: State = {
  viewState: {
    latitude: -23.6821604,
    longitude: -46.8754867,
    zoom: 5,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: POSITRON,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken:
      'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfcmFlYmp2cnQiLCJqdGkiOiIzOWVkZjM4YyJ9._zlwWiB2qt9vgWldISv0_MggG_Xe5WsdGa8Z9U4sWrc',
  },
  googleApiKey: 'AIzaSyC7bq_7eNChhiIrSsd3EFfCtcsF8HzuOrg', // only required when using a Google Basemap,
  googleMapId: '', // only required when using a Google Custom Basemap
};
