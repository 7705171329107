import {ScatterplotLayer, SolidPolygonLayer} from "@deck.gl/layers/typed";
import { v4 as uuidv4 } from 'uuid';

export default function MyLocationLayer(dt: any) {
  let opacity = dt[0].opacity;
  return new ScatterplotLayer({
    id: 'raioLayer-' + uuidv4(),
    data: dt,
    pickable: true,
    opacity: opacity,
    stroked: true,
    filled: true,
    radiusScale: 6,
    radiusMinPixels: 1,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: d => d.coordinates,
    getRadius: d => Math.sqrt(d.raio),
    getFillColor: d => d.color,
  });
}
