import { createSlice } from '@reduxjs/toolkit';
import OverlayLayer from "../components/layers/OverlayLayer";
import IsochroneLayer from "../components/layers/IsochroneLayer";
import RaioLayer from "../components/layers/RaioLayer";
import { removeLayer } from "@carto/react-redux";
import FilterOverlayLayer from "../components/layers/FilterOverlayLayer";
import OverlayAreaLayer from "../components/layers/OverlayAreaLayer";
import GdmGeoJsonLayer from "../components/layers/GDMGeoJsonLayer";
import GdmLocalizationLayer from "../components/layers/GDMLocalizationLayer";
import GDMEditableGeojsonLayer
  , { DRAW_LAYER_ID } from '../components/layers/GDMEditableGeojsonLayer';
import TematicoLayer from '../components/layers/TematicoLayer';
import FlashDataLayer from '../components/layers/FlashDataLayer';
import DesenhoAMaoLivreReadOnlyLayer
  from '../components/layers/DesenhoAMaoLivreReadOnlyLayer';
import RouteLayer from '../components/layers/RouteLayer';
import MyLocationLayer from '../components/layers/MyLocationLayer';

const slice = createSlice({
  name: 'map',
  initialState: {
    layers: [],
    callbackToSetCoordinates: Function,
  },
  reducers: {
    addDesenhoAMaoLivreReadonlyLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(DesenhoAMaoLivreReadOnlyLayer(action.coordinates, action.layer));
    },
    addIsolineLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(IsochroneLayer(action.geoJsonData, action.layer, action.id));
    },
    addOverlayLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(OverlayLayer(action.geoJsonData, action.layer));
    },
    addRouteLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(RouteLayer(action.geoJsonData, action.layer));
    },
    setCoordinates: (state, action) => {
      // @ts-ignore
      state.coordinates = action.coords;
    },
    addDrawLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(GDMEditableGeojsonLayer(action.coordinates, state.callbackToSetCoordinates, action.color));
    },
    addCallbackToSetCoordinates: (state, action) => {
      // @ts-ignore
      state.callbackToSetCoordinates = action.callbackToSetCoordinates;
    },
    addFilterOverlayLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(FilterOverlayLayer(action.geoJsonData, action.layer));
    },
    addGDMGeojsonLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(GdmGeoJsonLayer(action.geoJsonData, action.layer, action.id));
    },
    addTematicoLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(TematicoLayer(action.geoJsonData, action.layer, action.id));
    },
    addAreaLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(AreaLayer(action.geoJsonData, action.layer));
    },
    addGDMLocalizationLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(GdmLocalizationLayer(action.geoJsonData, action.layer, action.id));
    },
    addRaioLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(RaioLayer(action.payload.data));
    },
    addMyLocationLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(MyLocationLayer(action.payload.data));
    },
    addOverlayAreaLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(OverlayAreaLayer(action.geoJsonData, action.layer));
    },
    addFlashDataLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(FlashDataLayer(action.geoJsonData, action.layer, action.name));
    },
    clearLayers: (state, action) => {
      state.layers.forEach((layer: any) => {
        removeLayer(layer);
      });
      state.layers = [];
    },
    removeLayerById: (state, action) => {
      state.layers.forEach((layer: any) => {
        if (layer.id.startsWith(action.payload.id)) {
          removeLayer(layer);
        }
      });
      state.layers = [];
    },
  }
});

export default slice.reducer;

export const addDesenhoAMaoLivreReadonlyLayer = (coordinates: any, layer: any) => ({
  type: 'map/addDesenhoAMaoLivreReadonlyLayer',
  coordinates,
  layer
});
export const addIsolineLayer = (geoJsonData: any, layer: any, id: string) => ({
  type: 'map/addIsolineLayer',
  geoJsonData,
  layer,
  id
});
export const addOverlayLayer = (geoJsonData: any, layer: any) => ({
  type: 'map/addOverlayLayer',
  geoJsonData,
  layer
});
export const addRouteLayer = (geoJsonData: any, layer: any) => ({
  type: 'map/addRouteLayer',
  geoJsonData,
  layer
});
export const addFilterOverlayLayer = (geoJsonData: any, layer: any) => ({
  type: 'map/addFilterOverlayLayer',
  geoJsonData,
  layer
});
export const addGDMGeojsonLayer = (geoJsonData: any, layer: any, id: string) => ({
  type: 'map/addGDMGeojsonLayer',
  geoJsonData,
  layer,
  id
});
export const addTematicoLayer = (geoJsonData: any, layer: any, id: string) => ({
  type: 'map/addTematicoLayer',
  geoJsonData,
  layer,
  id
});
export const addGDMLocalizationLayer = (geoJsonData: any, layer: any, id: string) => ({
  type: 'map/addGDMLocalizationLayer',
  geoJsonData,
  layer,
  id
});
export const addRaioLayer = (data: any) => ({
  type: 'map/addRaioLayer',
  payload: {data},
});

export const addMyLocationLayer = (data: any) => ({
  type: 'map/addMyLocationLayer',
  payload: {data},
});

export const addOverlayAreaLayer = (geoJsonData: any, layer: any) => ({
  type: 'map/addOverlayAreaLayer',
  geoJsonData,
  layer,
});
export const addFlashDataLayer = (geoJsonData: any, layer: any, name: any) => ({
  type: 'map/addFlashDataLayer',
  geoJsonData,
  layer,
  name
});
export const addAreaLayer = (geoJsonData: any, layer: any) => ({
  type: 'map/addAreaLayer',
  geoJsonData,
  layer,
});
export const clearLayers = () => ({
  type: 'map/clearLayers',
});
export const removeLayerById = (id: string) => ({
  type: 'map/removeLayerById',
  payload: {id},
});

export const setCoordinates = (coords: any) => ({
  type: 'map/setCoordinates',
  coords,
});

export const addDrawLayer = (coordinates: any, color: any) => ({
  type: 'map/addDrawLayer',
  coordinates,
  color
});

export const addCallbackToSetCoordinates = (callbackToSetCoordinates: any) => ({
  type: 'map/addCallbackToSetCoordinates',
  callbackToSetCoordinates
});

