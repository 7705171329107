import { Navigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTE_PATHS } from 'routes';
import { CompanyApi } from 'service/http-out/company';
import { GlobalContext } from 'contexts/globalContext';

export default function ProtectedRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const { isAuthenticated, isLoading } = useAuth0();
  const companyId: any = localStorage.getItem('companyId');
  const userId: any = localStorage.getItem('id');
    //@ts-ignore
    const {setCompanyUserSigned, companyUserSigned} = React.useContext(GlobalContext);
  const accessToken = useSelector(
    (state) => localStorage.getItem('token'),
    );
  if (accessToken !== null) {
  // if (accessToken !== null) {
    return children;
  }
  // if (localStorage.getItem('role') === 'admin') {
  //   if (companyUserSigned === undefined) {
  //     CompanyApi.getCompanyByuserId(companyId).then((res) => {
  //       setCompanyUserSigned(res.data)
  //       console.log(res.data);
  //     }).catch((erro:any) => {
  //       console.log(erro.response.data);
  //       if(erro.response.data.message === "Unauthorized" && erro.response.data.statusCode === 401){
  //         return <Navigate to={ROUTE_PATHS.LOGIN} />
  //       }
  //     })
  //   }
  // }

  const notAuthenticated = !isLoading && !isAuthenticated && accessToken === null;
  // if (notAuthenticated) {
  if (accessToken === null) {
    return <Navigate to={ROUTE_PATHS.LOGIN} />;
  }

  return !!accessToken ? children : null;
}
