// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { v4 as uuidv4 } from 'uuid';

export const OVERLAY_LAYER_ID = 'overlay-layer';
export default function OverlayLayer(geoJsonData: any, layer: any) {

  return new IconLayer({
    id: OVERLAY_LAYER_ID +'-'+ uuidv4(),
    data: geoJsonData.features,
    pickable: true,
    getIcon: (d: any) => ({
      url: layer.iconUrl,
      width: layer.size,
      height: layer.size,
      anchorY: layer.size,
    }),
    sizeScale: layer.size,
    iconMapping: {
      marker: {x: 0, y: 0, width: layer.size, height: layer.size, mask: true}
    },
    getPosition: (d: any) => d.geometry.coordinates,
    getSize: (d: any) => 5,
    getColor: (d: any) => [Math.sqrt(d.exits), 140, 0],
  });
}
