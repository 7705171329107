// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { v4 as uuidv4 } from 'uuid';

export const FLASHDATA_LAYER_ID = 'flashdata-layer';
export default function FlashDataLayer(geoJsonData: any, layer: any, name: any) {
  function rgb(hex: any) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16),
      parseInt(result[3], 16)] : null;
  }

  return new GeoJsonLayer({
    id: FLASHDATA_LAYER_ID +'-'+ name +'-'+ uuidv4(),
    data: geoJsonData.features,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    opacity: 0.4,
    //@ts-ignore
    getLineColor: rgb(layer.color),
    //@ts-ignore
    getFillColor: layer.thickness / 10,
  });
}
