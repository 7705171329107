import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import {PolygonLayer} from "@deck.gl/layers";

function rgb(hex: any) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export default function IsochroneLayer(geoJsonData: any, layer: any, id: string) {
  return new PolygonLayer({
    id: id +'-'+ uuidv4(),
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: (d: any) => d.contour,
    // getElevation: (d: any) => d.population / d.area / 10,
    //@ts-ignore
    getFillColor: (d: any) => rgb(layer.color),
    opacity: layer.opacity / 10,
    getLineColor: [80, 80, 80],
    getLineWidth: 1
  });
}
