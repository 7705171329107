import { Filter } from 'components/modal/domain/filter';
import { Overlay } from 'components/views/admin/OverlaySettings/domain/overlaySettings';
import api from './http-client';

export const UserOverlayApi = {
  getUserOverlays(id: string) {
    return api.get(`/user-overlay/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },


  getUserLayers(id: string) {
    console.log(id)
    return api.get(`/user-overlay/layer/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getUserOverlaysFilters() {
    return api.get('/overlay-filter', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  CreateUserOverlayFilter(filters: any) {
    return api.post('/overlay-filter', filters, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getUserOverlayFilter(id: string) {
    return api.get(`/overlay-filter/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  updateUserOverlaysFilter(filters:any, id: string ) {
    return api.patch(`/overlay-filter/${id}`, filters, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  deleteUserOverlaysFilter(id: string) {
    return api.delete(`/overlay-filter/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getUserOverlaysThematics() {
    return api.get('/tematic-filter', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  deleteUserOverlaysThematic(id: string) {
    return api.delete(`/tematic-filter/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  createUserOverlaysThematic(thematic: any) {
    return api.post('/tematic-filter', thematic, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  updateUserOverlaysThematic(id: any, thematic: any) {
    return api.patch(`/tematic-filter/${id}`, thematic, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },
};
