// @ts-ignore
import { GeoJsonLayer, IconLayer, PolygonLayer } from '@deck.gl/layers';
import { v4 as uuidv4 } from 'uuid';

export const ROUTE_LAYER_ID = 'route-layer-';

function rgb(hex: any) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}
export default function RouteLayer(geoJsonData: any, layer: any) {

  return new GeoJsonLayer({
    id: ROUTE_LAYER_ID + uuidv4(),
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    extruded: false,
    pointType: 'polygon',
    // lineWidthScale: 5,
    // lineWidthMinPixels: 200,
    // lineWidthMaxPixels: 200,
    // pointRadiusMinPixels: 200,
    // pointRadiusMaxPixels: 200,
    // getPointRadius: 100,
    getLineWidth: 7,
    //@ts-ignore
    getFillColor: (d: any) => rgb(layer.color),
    //@ts-ignore
    getLineColor:  (d: any) => [0, 0, 0],
    opacity: layer.opacity / 10,
  });
}
