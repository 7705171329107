import { DrawPolygonMode, EditableGeoJsonLayer } from 'nebula.gl';
import { v4 as uuidv4 } from 'uuid';
export const DRAW_LAYER_ID = 'draw-layer-';

function rgb(hex: any) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}
export default function GDMEditableGeojsonLayer(coordinates: any, callbackToSetCoordinates: any, color: any) {
  let selectedFeatureIndexes = [0];

  // @ts-ignore
  return new EditableGeoJsonLayer({
    id: DRAW_LAYER_ID + uuidv4(),
    filled: true,
    pointRadiusMinPixels: 2,
    pointRadiusScale: 2000,
    //@ts-ignore
    extruded: false,
    getElevation: 1000,
    //@ts-ignore
    getFillColor: rgb(color),
    pickable: true,
    autoHighlight: true,
    mode: DrawPolygonMode,
    selectedFeatureIndexes,
    modeConfig:{
      enableSnapping: true
    },
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                ...coordinates
              ]
            ]
          }
        }
      ]
    },
    onEdit: (updatedData: any ) => {
      if (updatedData.editType === 'addFeature') {
        let coordinates2 = updatedData.updatedData.features[1].geometry.coordinates[0];
        // dispatch(setCoordinates(coordinates));
        console.log('coordinates: ', coordinates2);
        callbackToSetCoordinates(coordinates2, color);
      }
    },
  });
}
