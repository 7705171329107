import { useDispatch } from 'react-redux';
import React, {useEffect, useState} from 'react';
import {DARK_MATTER, POSITRON, VOYAGER} from "@carto/react-basemaps";
import {
  Box,
  Modal,
  makeStyles, MenuItem, Link
} from '@material-ui/core';
import Button from '@mui/material/Button';
import './urlhelp.css'
import {setBasemap, setViewState} from "@carto/react-redux";
import Escuro from '../../../../assets/img/Escuro.png';
import Claro from'../../../../assets/img/Claro.png';
import Voyager from'../../../../assets/img/Voyager.png';
import Noturno from'../../../../assets/img/Noturno.png';
import Noturno_simplificado from'../../../../assets/img/Noturno_simplificado.png';
import Satelite from'../../../../assets/img/Satelite.jpg';
import Transito from'../../../../assets/img/Transito.png';
import Diurno from'../../../../assets/img/Diurno.png';
import { BaseMapApi } from "../../../../service/http-out/baseMap";
import { GlobalContext } from '../../../../contexts/globalContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  editFilter: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    flexDirection: 'row',
  },

  scrollFilter: {
    overflowY: 'scroll',
  },

  img: {
    width: '200px',
    height: '200px',
    borderRadius: '10px',
    margin: '20px',
    cursor: 'pointer',
    fontSize:'400px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity:'50%',
      transition: 'all 0.6s ease',
      transform: 'scale(1.1)',
      boxShadow: 'inset 0 0 0 25px #000000',

    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },

  div: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  button:{
    position: 'absolute',
    display:'fixed',
  },
  divMapm: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  divMap: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflow:'scroll',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    overflowY:'scroll',
  },
  columnBack: {
    display: 'flex',
    width: '60%',
    alignItems: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    backgroundColor: '#eeefef',
    marginLeft: '5px',
    marginRight: '5px',
  },
});

const style = {
  flexDirection: 'column',
  overflow:'hidden',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '75%',
  maxHeight:'550px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function UrlHelpModal({ isUrlHelpModalVisible, setUrlHelpModalVisible } : { isUrlHelpModalVisible: boolean,
  setUrlHelpModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  if (!isUrlHelpModalVisible) {
    return (<></>);
  }
  const handleClose = () => setUrlHelpModalVisible(false);
  const dispatch = useDispatch();
  const [baseMaps, setBaseMaps] = useState([]);
  const [showVoyager ,setShowVoyager] = useState(true);
  const [showClear ,setShowClear] = useState(true);
  const [showDark ,setShowDark] = useState(true);
  const [showNocturnal ,setShowNocturnal] = useState(false);
  const [showSimplifiedNight ,setShowSimplifiedNight] = useState(false);
  const [showTraffic ,setShowTraffic] = useState(false);
  const [showSatellite ,setShowSatellite] = useState(false);
  const [showDaytime ,setShowDaytime] = useState(false);
  const [urlHelp ,setUrlHelp] = useState([]);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  //@ts-ignore
  const { companyUserSigned } = React.useContext(GlobalContext);

  useEffect(()=>{
    if (companyUserSigned === undefined) {
      // getUrlHelperFromCompany()
    } else {
      setUrlHelp(companyUserSigned.urlHelper);
    }
  },[baseMaps])

  return (
    <Modal open={isUrlHelpModalVisible} onClose={handleClose}>
      <Box sx={style}>
        <div>
          <h1>{t<string>('urlhelp.title')}</h1>
        </div>
        <Box className={classes.row}>
          {urlHelp.map((element: any) => (
            <Link href={element.value} underline="always" target="_blank" rel="noopener">
              {element.label}
            </Link>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}
