import { Company } from "components/views/admin/Company/domain/company";
import { useState, createContext } from "react";

export const GlobalContext = createContext({});

//@ts-ignore
function GlobalContextProvider({ children }) {
    const [editId, setEditId] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [editVariableId, setEditVariableId] = useState('');
    const [isVariableEdit, setIsVariableEdit] = useState(false);
    const [geoId, setGeoId] = useState('');
    const [isGeoEdit, setIsGeoEdit] = useState(false);
    const [userSignedId, setUserSignedId] = useState('');
    const [userSignedName, setUserSignedName] = useState('');
    const [CompanySignedId, setCompanySignedId] = useState('');
    const [companyUserSigned, setCompanyUserSigned] = useState<Company>();
    const [overlayPontosChecked, setOverlayPontosChecked] = useState({});
    const [overlayAreaChecked, setOverlayAreaChecked] = useState({});
    const [userSigned, setUserSigned] = useState({});
    const [areasContext, setAreasContext] = useState<Array<any>>([])
    const [locationContext, setLocationContext] = useState<Array<any>>([])

    return (
        <GlobalContext.Provider value={{
            editId, setEditId,
            isEdit, setIsEdit,
            geoId, setGeoId,
            isGeoEdit, setIsGeoEdit,
            userSignedId, setUserSignedId,
            CompanySignedId, setCompanySignedId,
            editVariableId, setEditVariableId,
            isVariableEdit, setIsVariableEdit,
            companyUserSigned, setCompanyUserSigned,
            overlayPontosChecked, setOverlayPontosChecked,
            overlayAreaChecked, setOverlayAreaChecked,
            userSigned, setUserSigned,
            userSignedName, setUserSignedName,
            areasContext, setAreasContext,
            locationContext, setLocationContext
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
export default GlobalContextProvider;
