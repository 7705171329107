// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { v4 as uuidv4 } from 'uuid';

export const OVERLAY_AREA_LAYER_ID = 'overlay-area-layer';
export default function OverlayAreaLayer(geoJsonData: any, area: any) {
  function rgb(hex: any) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  return new GeoJsonLayer({
    id: OVERLAY_AREA_LAYER_ID +'-'+ uuidv4(),
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    extruded: false,
    pointType: 'polygon',
    getFillColor: (d: any) => [200, 0, 80, 180],
    getLineColor:  (d: any) => [250, 110, 80, 120],
    opacity: 0.5,

  });
}
