/**
 * Setup the global elements of the react app:
 *  - redux store
 *  - react router
 *  - main component: App
 */
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { createCartoSlice } from '@carto/react-redux';
import App from './App';
import { initialState } from 'store/initialStateSlice';
import store from './store/store';
// @ts-ignore
import { setDefaultCredentials } from '@deck.gl/carto';
import   GlobalContextProvider   from './contexts/globalContext';
import './i18n';
import { createRoot } from 'react-dom/client';

// @ts-ignore
store.injectReducer('carto', createCartoSlice(initialState));

setDefaultCredentials({ ...initialState.credentials });

const AppProvider = (
<GlobalContextProvider>
  <OauthProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </OauthProvider>
</GlobalContextProvider>
);

// @ts-ignore
const container = createRoot(document.getElementById('root'));
container.render(AppProvider);

function OauthProvider({ children }: { children: JSX.Element }) {
  const token = localStorage.getItem('token');
  // if (!initialState.oauth) {
  if (!token) {
    return children;
  }
  // const { domain, clientId, scopes, audience } = initialState.oauth;
  // if (!clientId) {
  //   alert(
  //     'Need to define a clientId. Please check the file store/initalStateSlice.js',
  //   );
  // }

  return (
    <>
    {/*<Auth0Provider*/}
    {/*   domain={domain}*/}
    {/*   clientId={clientId}*/}
    {/*   redirectUri={window.location.origin}*/}
    {/*   scopes={scopes.join(' ')}*/}
    {/*   audience={audience}*/}
    {/*   cacheLocation='localstorage'*/}
    {/* >*/}
      {children}
    {/*</Auth0Provider>*/}
    </>
  );
}
